import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Deal from "../components/Deal";
import SearchBar from "../components/SearchBar";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Paper,
  GridList,
  GridListTile,
  AppBar,
  Toolbar,
  IconButton,
  Link,
} from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import withRoot from "../app/withRoot";
import Footer from "../components/Footer";
import LocaleMenu from "../components/LocaleMenu";
import { FormattedMessage } from "react-intl";
import ReservationModal from "./ReservationModal";
import { Helmet } from "react-helmet";
import Parallax from "../components/Parallax";
import SearchBarAlt from "../components/SearchBarAlt";
import { fetchDealsIfNeeded } from "../actions/deals";
import { updateLocaleIfneeded } from "../actions/locale";
import { LOCALES } from "../config";
import DealsFeed from "../components/DealsFeed";
import chunk from "lodash.chunk";
import { Link as RouterLink } from "react-router-dom";
import TopBarMain from "../components/ToolBarMain";
import ToolBarMain from "../components/ToolBarMain";

const styles = (theme) => ({
  root: {
    overflowX: "hidden",
  },
  searchSection: {
    backgroundImage: `url("top.jpg")`,
    backgroundSize: "cover",
    backgroundPosition: `center`,
    color: theme.palette.common.white,
  },
  main: {
    marginTop: -theme.spacing.unit * 4,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 6,
      marginRight: theme.spacing.unit * 6,
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 5,
    position: "relative",
    zIndex: "3",
  },
  dealsSection: {
    // padding: theme.spacing.unit * 5,
  },
  feedImage: {
    marginRight: theme.spacing.unit * 1,
  },
  textEmpty: {
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20,
  },
});

class DealsPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    position: PropTypes.object.isRequired,
    deals: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const localeParam = this.props.match.params.locale;
    const { dispatch, position, lang } = this.props;

    if (localeParam) {
      dispatch(updateLocaleIfneeded(localeParam));
    }
    dispatch(fetchDealsIfNeeded(position, lang));
  }

  componentDidUpdate(prevProps) {
    const { dispatch, position, lang, locale } = this.props;
    if (prevProps.location !== this.props.location) {
      const localeParam = this.props.match.params.locale;

      if (localeParam) {
        dispatch(updateLocaleIfneeded(localeParam));
      } 
      dispatch(fetchDealsIfNeeded(position, lang));
    }
  }

  handleSearchClick = (query) => {
    if (query) {
      this.props.history.push(`/restaurants?search=${query}`);
    }
  };

  handleLocaleClick = (newLocale) => {
    const { dispatch, locale } = this.props;

    if (newLocale !== locale) {
      this.props.history.replace(`/${newLocale}`);
    }
  };

  render() {
    const { location, deals, isFetching, classes, lang, locale } = this.props;

    const isEmpty =
      deals.length === 0

    return (
      <div className={classes.root}>
        <Helmet>
          <title>RestoLastMinute</title>
          <FormattedMessage id="home.subTitle">
            {(message) => <meta name="description" content={message} />}
          </FormattedMessage>
          <meta
            name="keywords"
            content="restaurants, resto, restaurant, resto minute, minute, manger, reserver"
          />
        </Helmet>
        <div>
          <AppBar
            position="sticky"
            elevation={0}
            style={{
              background: "transparent",
              boxShadow: "none",
              position: "absolute",
            }}
          >
          <ToolBarMain
            onLocaleClick={this.handleLocaleClick}
            locale={locale}
          />
          </AppBar>
          <Parallax image={"top.jpg"}>
            <Grid
              container
              direction="column"
              justify="space-around"
              spacing={24}
              alignItems="center"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <img
                      alt="RESTO"
                      height="40px"
                      src={"RESTO.png"}
                      style={{ marginRight: "8px" }}
                    />
                  </Grid>
                  <Grid item>
                    <img
                      alt="LAST"
                      height="40px"
                      src={"LAST.png"}
                      style={{ marginRight: "8px" }}
                    />
                  </Grid>
                  <Grid item>
                    <img alt="MINUTE" height="40px" src={"MINUTE.png"} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <SearchBar
                  onSearchClick={this.handleSearchClick}
                  value={location.address}
                />
              </Grid>
            </Grid>
          </Parallax>
        </div>

        <Paper className={classes.main} elevation={8}>
          <Grid
            container
            className={classes.dealsSection}
            direction="row"
            spacing={40}
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            {isFetching ? (
              <CircularProgress />
            ) : isEmpty ? (
              <Typography
                variant="h4"
                className={classes.textEmpty}
                color="textSecondary"
                align="center"
              >
                <FormattedMessage id="deals.empty" />
              </Typography>
            ) : (
              deals.map(deal => (
                <Grid item key={deal.id}>
                  <Deal deal={deal} key={deal.id} />
                </Grid>
              ))
            )}
          </Grid>
        </Paper>
        <Footer locale={locale} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { deals, locale } = state;

  const { isFetching, items } = deals;

  return {
    position: locale.position.query,
    deals: items,
    isFetching,
    lang: locale.lang,
    locale: locale.locale,
  };
};

export default withRouter(
  connect(mapStateToProps)(withRoot(withStyles(styles)(DealsPage)))
);
