import React from "react";
import {
  BrowserRouter,
  Route,
  HashRouter,
  Switch,
  Redirect,
} from "react-router-dom";
import DealsPage from "../containers/DealsPage";
import AboutPage from "../containers/AboutPage";
import UsagePage from "../containers/UsagePage";
import RestaurantsPage from "../containers/RestaurantsPage";
import RestaurantPage from "../containers/RestaurantPage";
import FaqPage from "../containers/FaqPage";
import RawDealsPage from "../containers/RawDealsPage";
import PropTypes from "prop-types";
import DisclaimerPage from "../containers/DisclaimerPage";

class Routes extends React.Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
  };

  render() {
    const { dispatch, lang } = this.props;

    const localesPath = "/:locale(fr|be_fr|be_nl)?";

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/:locale(fr|be_fr|be_nl)?" component={DealsPage} />
          <Route exact path="/index.html" component={DealsPage} />

          <Route
            path={`${localesPath}/restaurants/:id/:slug`}
            component={RestaurantPage}
          />

          <Route path="/restaurants" component={RestaurantsPage} />

          <Route path="/disclaimer" component={DisclaimerPage} />
          <Route path="/usage" component={UsagePage} />
          <Route path="/faq" component={FaqPage} />
          <Route path="/about" component={AboutPage} />

          <Route path="/list-deals" component={RawDealsPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routes;
