import { ENDPOINT } from "../config";

export const DEAL_SELECT = "DEAL_SELECT";
export const DEAL_REQUEST = "DEAL_REQUEST";
export const DEAL_SUCCESS = "DEAL_SUCCESS";
export const DEAL_FAILURE = "DEAL_FAILURE";

export const dealSelect = deal => ({
  type: DEAL_SELECT,
  deal: deal
});

export const dealRequest = id => ({
  type: DEAL_REQUEST,
  id
});

export const dealSuccess = (id, json) => ({
  type: DEAL_SUCCESS,
  deal: json.deal
});

export const dealFailure = (id, error) => ({
  type: DEAL_FAILURE,
  error: error
});

export const fetchDealIfNeeded = id => (dispatch, getState) => {
  const { deals, locale } = getState();
  const deal = deals.items.find(deal => deal.id === id);
  if (deal) {
    return dispatch(dealSelect(deal));
  }
  return dispatch(fetchDeal(id, locale.lang));
};

const fetchDeal = (id, lang = "fr") => dispatch => {
  dispatch(dealRequest(id));
  //location is a dummy query here
  return fetch(
    `${ENDPOINT}/b2c/dayDeals/${id}?lat=50.846921&lon=4.352425&locale=${lang}`
  )
    .then(response => response.json())
    .then(json => dispatch(dealSuccess(id, json)));
};
