import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withRoot from "../app/withRoot";
import withStyles from "@material-ui/styles/withStyles";
import {
  Grid,
  Typography,
  Button,
  Toolbar,
  AppBar,
  Link,
  Paper
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import LocaleMenu from "../components/LocaleMenu";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import { Link as RouterLink } from "react-router-dom";
import { updateLocaleIfneeded } from "../actions/locale";

import { LOCALES } from "../config";
import TopBar from "../components/TopBar";

const styles = theme => ({
  root: {
    overflowX: "hidden"
  },
  topSection: {
    backgroundImage: `url("top.jpg")`,
    backgroundSize: "cover",

    color: theme.palette.common.white
  },
  contentSection: {
    paddingLeft: "20%",
    paddingRight: "20%",
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 5
  }
});

class AboutPage extends Component {
  handleLocaleClick = lang => {
    this.props.dispatch(updateLocaleIfneeded(lang));
  };

  render() {
    const { classes, locale, lang } = this.props;

    return (
      <div className={classes.root}>
        <ScrollToTop />
        <TopBar
            onLocaleClick={this.handleLocaleClick}
            locale={locale}
          />
        <main className={classes.main} />
        {lang === "fr" ? (
          <Grid
            container
            direction="column"
            className={classes.contentSection}
            spacing={40}
          >
            <Grid item>
              <Typography variant="h4">
                <FormattedMessage id="about.title" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" gutterBottom>
                <FormattedMessage id="about.section1.title" />
              </Typography>
              <Typography variant="body1" p>
                <FormattedMessage id="about.section1.text" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" gutterBottom>
                <FormattedMessage id="about.section2.title" />
              </Typography>

              <Typography variant="body1" p gutterBottom>
                <FormattedMessage id="about.section2.text" />
              </Typography>

              <Typography variant="body1" p>
                <FormattedMessage id="about.section2.text2" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" gutterBottom>
                <FormattedMessage id="about.section3.title" />
              </Typography>

              <Typography variant="body1" p gutterBottom>
                <FormattedMessage id="about.section3.text" />
              </Typography>

              <Typography variant="body1" p>
                <FormattedMessage id="about.section3.text2" />
              </Typography>
            </Grid>
            <Grid item />
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            className={classes.contentSection}
            spacing={40}
          >
            <Grid item>
              <Typography variant="h4" gutterBottom>
                <FormattedMessage id="about.title" />
              </Typography>

              <Typography variant="body1" p gutterBottom>
                <FormattedMessage id="about.section1.text" />
              </Typography>
              <Typography variant="body1" p gutterBottom>
                <FormattedMessage id="about.section1.text2" />
              </Typography>
              <Typography variant="body1" p gutterBottom>
                <FormattedMessage id="about.section1.text3" />
              </Typography>
            </Grid>
          </Grid>
        )}

        <Footer locale={locale} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locale } = state;

  const { lang } = locale;

  return {
    lang,
    locale: locale.locale
  };
};

export default withRouter(
  connect(mapStateToProps)(withRoot(withStyles(styles)(AboutPage)))
);
