export const belgium = {
  brussels: {
    query: "brussels",
    lat: 50.8465573,
    lng: 4.351697
  },
  liege: {
    query: "liege",
    lat: 50.6451381,
    lng: 5.5734203
  },
  namur: {
    query: "namur",
    lat: 50.4665284,
    lng: 4.8661892
  },
  mons: {
    query: "mons",
    lat: 50.4549568,
    lng: 3.951958
  },
  charleroi: {
    query: "charleroi",
    lat: 50.4120332,
    lng: 4.4436244
  },
  antwerp: {
    query: "antwerp",
    lat: 51.2211097,
    lng: 4.3997081
  },
  ghent: {
    query: "ghent",
    lat: 51.0538286,
    lng: 3.7250121
  },
  bruges: {
    query: "bruges",
    lat: 51.2085526,
    lng: 3.226772
  }
};

export const france = {
  paris: {
    query: "paris",
    lat: 48.8566,
    lng: 2.3522
  },
  lyon: {
    query: "lyon",
    lat: 45.764,
    lng: 4.8357
  },
  lille: {
    query: "lille",
    lat: 50.6292,
    lng: 3.0573
  },
  marseille: {
    query: "marseille",
    lat: 43.2965,
    lng: 5.3698
  },
  toulouse: {
    query: "toulouse",
    lat: 43.6047,
    lng: 1.4442
  },
  nantes: {
    query: "nantes",
    lat: 47.2184,
    lng: 1.5536
  }
};

export const all = {
  ...france,
  ...belgium
};
