import { belgium, france } from "./cities";

export const ENDPOINT = "https://www.restolastminute.com";

export const LOCALES = {
  be_fr: {
    locale: "be_fr",
    countryName: "Belgique",
    language: "Français",
    languageIso: "FR",
    position: belgium["brussels"],
    cities: Object.keys(belgium),
    instagram: "https://www.instagram.com/restolastminute/",
    mailto: "hello@restolastminute.com",
    facebook: "https://www.facebook.com/restolastminute/",
    b2b: "https://restolastminute.pro/welcome-francais/"
  },
  be_nl: {
    locale: "be_nl",
    countryName: "België",
    language: "Nederlands",
    languageIso: "NL",
    position: belgium["brussels"],
    cities: Object.keys(belgium),
    instagram: "https://www.instagram.com/restolastminute/",
    mailto: "hello@restolastminute.com",
    facebook: "https://www.facebook.com/restolastminute/",
    b2b: "https://restolastminute.pro/welcome-nederlands/"
  },
};

export const DEFAULT_LOCALE = LOCALES["be_fr"];
