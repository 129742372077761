import { ENDPOINT } from "../config";

export const RESERVATION_POST_REQUEST = "RESERVATION_POST_REQUEST";
export const RESERVATION_POST_SUCCESS = "RESERVATION_POST_SUCCESS";
export const RESERVATION_POST_FAILURE = "RESERVATION_POST_FAILURE";

export const reservationPostRequest = reservation => ({
  type: RESERVATION_POST_REQUEST,
  reservation
});

export const reservationPostSuccess = (reservation, response) => ({
  type: RESERVATION_POST_SUCCESS,
  reservation
});

export const reservationPostFailure = (dealId, error) => ({
  type: RESERVATION_POST_FAILURE,
  error: error
});

export const postReservation = (reservation, lang = "fr") => dispatch => {
  dispatch(reservationPostRequest(reservation.dayDealId));

  return fetch(`${ENDPOINT}/b2c/reservation?locale=${lang}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(reservation)
  })
    .then(response => {
      if (!response.ok) {
        throw Error(`Request rejected with status ${response.statusText}`);
      } else {
        response.json();
      }
    })
    .then(json => dispatch(reservationPostSuccess(reservation, json)))
    .catch(error =>
      dispatch(reservationPostFailure(reservation, error.message))
    );
};

export const postReservationRequest = (
  reservation,
  lang = "fr"
) => dispatch => {
  dispatch(reservationPostRequest(reservation.restaurantId));

  return fetch(`${ENDPOINT}/b2c/reservation-request?locale=${lang}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(reservation)
  })
    .then(response => {
      if (!response.ok) {
        throw Error(`Request rejected with status ${response.statusText}`);
      } else {
        dispatch(reservationPostSuccess(reservation, null));
      }
    })
    .catch(error =>
      dispatch(reservationPostFailure(reservation, error.message))
    );
};
