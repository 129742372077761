import { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Paper,
  Typography,
  TextField,
  Grid,
  MenuItem,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import green from "@material-ui/core/colors/green";
import CheckIcon from "@material-ui/icons/Check";
import classNames from "classnames";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { isBefore, isAfter } from "date-fns";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import FacebookIcon from "@material-ui/icons/Facebook";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1465ce"
    }
  }
});
const styles = theme => ({
  textField: {
    width: 200
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  tos: {
    marginTop: theme.spacing.unit * 2
  },
  reservationSuccess: {
    marginTop: theme.spacing.unit * 4
  }
});

const timeToString = (hour, minute) => {
  const hourString = hour < 10 ? "0" + hour : hour;
  const minuteString = minute < 10 ? "0" + minute : minute;
  return hourString + ":" + minuteString;
};

const lunchTimes = [
  { value: timeToString(12, 0), hour: 12, minute: 0 },
  { value: timeToString(12, 30), hour: 12, minute: 30 },
  { value: timeToString(13, 0), hour: 13, minute: 0 }
];

const dinnerTimes = (
  startHour = 18,
  startMinute = 0,
  endHour = 23,
  endMinute = 0
) => {
  let array = Array.apply(1, Array((endHour - startHour + 1) * 2)).map(
    (value, index) => {
      const minute = index % 2 ? 30 : 0;
      const hour = parseInt(startHour + index / 2);
      return { value: timeToString(hour, minute), hour, minute };
    }
  );

  if (startMinute === 30) {
    array.shift();
  }

  if (endMinute === 0) {
    array.pop();
  }

  return array;
};

class ReservationModal extends Component {
  static propTypes = {
    deal: PropTypes.object.isRequired,
    onReservationClick: PropTypes.func.isRequired,
    reservation: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    open: false,
    places: 1,
    firstName: localStorage.getItem("firstName") || "",
    lastName: localStorage.getItem("lastName") || "",
    email: localStorage.getItem("email") || "",
    phoneNumber: localStorage.getItem("phoneNumber") || ""
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleReservationClick = () => {
    const splittedTime = this.state.time.split(":");
    const hour = parseInt(splittedTime[0]);
    const minute = parseInt(splittedTime[1]);
    const phoneNumber = this.state.phoneNumber;

    const reservation = {
      dayDealId: this.props.deal.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: phoneNumber,
      places: this.state.places,
      hour,
      minute
    };

    if (
      reservation.firstName &&
      reservation.lastName &&
      reservation.email &&
      reservation.phoneNumber
    ) {
      localStorage.setItem("firstName", reservation.firstName);
      localStorage.setItem("lastName", reservation.lastName);
      localStorage.setItem("email", reservation.email);
      localStorage.setItem("phoneNumber", reservation.phoneNumber);

      this.props.onReservationClick(reservation);
    } else {
      reservation.error = "All fields are required";
    }
  };

  responseFacebook = response => {
    this.setState({ firstName: response.first_name });
    this.setState({ lastName: response.last_name });
    this.setState({ email: response.email });
  };

  renderDialog = () => {};

  render() {
    const { classes, deal, reservation } = this.props;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: reservation.item
    });

    const now = new Date();

    const timeArray = (deal.lunch ? lunchTimes : [])
      .concat(
        deal.dinner
          ? dinnerTimes(
              deal.startBookingHour,
              deal.startBookingMinute,
              deal.endBookingHour,
              deal.endBookingMinute
            )
          : []
      )
      .filter(time =>
        isAfter(
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            time.hour,
            time.minute
          ),
          now
        )
      );

    if (!this.state.time && timeArray[0])
      this.setState({ time: timeArray[0].value });

    const endBookingDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      deal.dinner ? deal.endBookingHour : 13,
      deal.dinner ? deal.endBookingMinute : 1
    );

    return reservation.item ? (
      <div>
        <Button
          onClick={this.handleOpen}
          color="primary"
          variant="contained"
          className={buttonClassname}
        >
          <CheckIcon /> <FormattedMessage id="bookingButton.booked" />
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle variant="h6" id="modal-title">
            <FormattedMessage id="bookingDialog.title" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="bookingDialog.success" />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    ) : (
      <div>
        <Button
          onClick={this.handleOpen}
          variant="outlined"
          color="primary"
          disabled={deal.availablePlaces < 1 || isBefore(endBookingDate, now)}
        >
          <FormattedMessage id="bookingDialog.title" />
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle variant="h6" id="modal-title">
            <FormattedMessage id="bookingDialog.title" />
          </DialogTitle>
          <DialogContent>
            <FacebookLogin
              appId="232927020233971"
              autoLoad={true}
              fields="first_name, last_name, email"
              callback={this.responseFacebook}
              disableMobileRedirect={true}
              render={renderProps => (
                <ThemeProvider theme={theme}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={renderProps.onClick}
                    className={classes.button}
                  >
                    <FacebookIcon className={classes.leftIcon} /> Facebook
                    Connect
                  </Button>
                </ThemeProvider>
              )}
            />
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <FormattedMessage id="bookingDialog.firstName">
                  {message => (
                    <TextField
                      required
                      id="firstName"
                      label={message}
                      error={this.state.firstName === ""}
                      className={classes.textField}
                      value={this.state.firstName}
                      onChange={this.handleChange("firstName")}
                      margin="normal"
                    />
                  )}
                </FormattedMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormattedMessage id="bookingDialog.lastName">
                  {message => (
                    <TextField
                      required
                      id="lastName"
                      label={message}
                      error={this.state.lastName === ""}
                      className={classes.textField}
                      value={this.state.lastName}
                      onChange={this.handleChange("lastName")}
                      margin="normal"
                    />
                  )}
                </FormattedMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormattedMessage id="bookingDialog.email">
                  {message => (
                    <TextField
                      required
                      id="email"
                      label={message}
                      type="email"
                      className={classes.textField}
                      value={this.state.email}
                      onChange={this.handleChange("email")}
                      margin="normal"
                    />
                  )}
                </FormattedMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormattedMessage id="bookingDialog.phoneNumber">
                  {message => (
                    <TextField
                      required
                      id="phone-number"
                      label={message}
                      type="tel"
                      className={classes.textField}
                      value={this.state.phoneNumber}
                      onChange={this.handleChange("phoneNumber")}
                      margin="normal"
                    />
                  )}
                </FormattedMessage>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormattedMessage id="bookingDialog.placeNumber">
                  {message => (
                    <TextField
                      select
                      id="places-number"
                      label={message}
                      value={this.state.places}
                      onChange={this.handleChange("places")}
                      className={classes.textField}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                    >
                      {Array.apply(1, Array(deal.availablePlaces)).map(
                        (value, i) => (
                          <MenuItem key={i} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  )}
                </FormattedMessage>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormattedMessage id="bookingDialog.hour">
                  {message => (
                    <TextField
                      select
                      id="time"
                      label={message}
                      className={classes.textField}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      value={this.state.time}
                      onChange={this.handleChange("time")}
                      margin="normal"
                    >
                      {timeArray.map((value, i) => (
                        <MenuItem key={i} value={value.value}>
                          {value.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </FormattedMessage>
              </Grid>
              <Grid item xs={12}>
                {reservation.error ? (
                  <Typography variant="subtitle2" color="error">
                    Error: {reservation.error}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.tos} variant="subtitle1">
                  <FormattedHTMLMessage id="bookingDialog.tos" />
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={this.handleReservationClick}
              disabled={reservation.isFetching}
            >
              <FormattedMessage id="bookingDialog.book" />
            </Button>
          </DialogActions>
          {reservation.isFetching ? <LinearProgress /> : null}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ReservationModal);
