import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Deal from "../components/Deal";

import SearchBar from "../components/SearchBar";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Paper,
  GridList,
  GridListTile,
  AppBar,
  Toolbar,
  IconButton,
  Link
} from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import withRoot from "../app/withRoot";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { fetchDealsIfNeeded } from "../actions/deals";
import { updateLocaleIfneeded } from "../actions/locale";

const styles = theme => ({
  root: {
    overflowX: "hidden",
    overflowY: "hidden"
  },
  dealsSection: {
    padding: theme.spacing.unit * 5
  },
  textEmpty: {
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20
  }
});

class RawDealsPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    deals: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { dispatch } = this.props;

    const lang = queryString.parse(this.props.location.search).lang || "fr";
    dispatch(updateLocaleIfneeded(lang));

    const position =
      queryString.parse(this.props.location.search).location || "brussels";

    dispatch(fetchDealsIfNeeded(position));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const { dispatch, limit } = this.props;

      const lang = queryString.parse(this.props.location.search).lang || "fr";
      dispatch(updateLocaleIfneeded(lang));

      const position =
        queryString.parse(this.props.location.search).location || "brussels";

      dispatch(fetchDealsIfNeeded(position));
    }
  }

  render() {
    const { location, deals, isFetching, classes, lang } = this.props;
    
    const isEmpty = deals.length === 0;
    return (
      <div className={classes.root}>
        <Grid
          container
          className={classes.dealsSection}
          spacing={40}
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          {isFetching ? (
            <CircularProgress />
          ) : isEmpty ? (
            <Typography
              variant="h4"
              className={classes.textEmpty}
              color="textSecondary"
              align="center"
            >
              <FormattedMessage id="deals.empty" />
            </Typography>
          ) : (
            deals.map((deal) => (
              <Grid item key={deal.id}>
              <Deal deal={deal} key={deal.id} webview={true} />
            </Grid>
            ))

          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { deals, locale } = state;

  const { isFetching, items } = deals;
  const { lang } = locale;

  return {
    position: locale.position.query,
    deals: items,
    isFetching,
    lang,
    locale: locale.locale
  };
};

export default withRouter(
  connect(mapStateToProps)(withRoot(withStyles(styles)(RawDealsPage)))
);
