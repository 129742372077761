import { LOCALES } from "../config";

export const LOCALE_UPDATE = "LOCALE_UPDATE";

export const localeUpdate = (locale, position) => ({
  type: LOCALE_UPDATE,
  locale: locale,
  position: position
});

export const updateLocaleIfneeded = localeQuery => (dispatch, getState) => {
  const { locale } = getState();

  if (localeQuery === locale.locale) {
    return;
  }

  const newLocale = LOCALES[localeQuery];

  if (!newLocale) {
    return;
  }

  dispatch(localeUpdate(newLocale.locale, newLocale.position));
};
