import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withRoot from "../app/withRoot";
import withStyles from "@material-ui/styles/withStyles";
import {
  Grid,
  Typography,
  Button,
  Toolbar,
  AppBar,
  Link,
  Paper
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import LocaleMenu from "../components/LocaleMenu";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import { Link as RouterLink } from "react-router-dom";
import { updateLocaleIfneeded } from "../actions/locale";
import { LOCALES } from "../config";
import ToolBarMain from "../components/ToolBarMain";
import TopBar from "../components/TopBar";

const styles = theme => ({
  root: {
    overflowX: "hidden"
  },
  topSection: {
    backgroundImage: `url("top.jpg")`,
    backgroundSize: "cover",

    color: theme.palette.common.white
  },
  contentSection: {
    paddingLeft: "20%",
    paddingRight: "20%",
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 5
  }
});

class FaqPage extends Component {
  handleLocaleClick = lang => {
    this.props.dispatch(updateLocaleIfneeded(lang));
  };

  render() {
    const { classes, lang, locale } = this.props;
    return (
      <div className={classes.root}>
        <ScrollToTop />
        <TopBar
            onLocaleClick={this.handleLocaleClick}
            locale={locale}
          />
        <main className={classes.main} />
        {lang === "fr" ? (
          <div className={classes.contentSection}>
            <Typography>
              <p>
                <h3>1. Comment ça marche ?</h3>
                RestoLastMinute.com est un service gratuit de réservation de
                restaurants en ligne pour le soir même. Nos partenaires
                restaurateurs vous proposent tous les jours des disponibilités
                avec une réduction sur l’addition.
              </p>
              <p>
                4 étapes pour réserver et profiter le soir même :
                <ul>
                  <li>
                    1. Consultez les offres ou recherchez (via la zone de
                    recherche) le restaurant de votre choix.
                  </li>
                  <li>
                    2. Réservez en complétant :
                    <ul>
                      <li> vos coordonnées, </li>
                      <li>
                        le nombre de couverts souhaités en fonction des
                        disponibilités,
                      </li>
                      <li>votre heure d’arrivée. </li>
                    </ul>
                  </li>
                  <li>
                    3. Confirmez votre commande en cliquant sur réserver. Un
                    e-mail de confirmation reprenant l’ensemble des données
                    inhérentes à votre réservation ainsi que votre numéro de
                    réservation vous sera immédiatement envoyé. De plus dans
                    l’application, vous trouverai dans l’onglet ma réservation,
                    la réservation du jour.
                  </li>
                  <li>
                    4. Présentez-vous au restaurant avec votre e-mail de
                    confirmation, votre application iOS ou Android (Ma
                    réservation) ou en mentionnant tout simplement que vous avez
                    réservé avec RestoLastMinute.com.
                  </li>
                </ul>
              </p>
              <p>
                <h3>2. Suis-je sur que ma réservation est faite ?</h3>
                Chaque réservation effectuée via RestoLastMinute.com est
                immédiatement confirmée par  un e- mail de qui vous est  envoyé.
                Si vous avez un quelconque problème de réception de nos e-
                mails, veuillez prendre contact avec nous par e-mail à
                hello@restolastminute.com. N’hésitez jamais surtout en période de
                vacances à téléphoner au restaurateur pour vérifier qu’il est
                bien ouvert.
              </p>
              <p>
                <h3>3. Comment puis-je annuler ma réservation ?</h3>
                Il suffit de cliquer sur le bouton d’annulation dans votre mail
                ou l’application, le restaurateur sera averti immédiatement
              </p>
              <p>
                <h3>4. Votre service est-il gratuit ?</h3>
                RestoLastMinute.com est un service totalement gratuit créé pour
                faciliter vos réservations en ligne. Notre système est financé
                par les restaurateurs au travers d’un abonnement mensuel. Nous
                ne demandons aucune commission supplémentaire aux restaurateurs,
                ceux-ci n’ont qu’un abonnement mensuel à payer et sont ensuite
                entièrement libre de choisir le type de promotion ou le nombre
                de places qu’ils offrent sur notre plate-forme.
              </p>
              <p>
                <h3>
                  5. Où puis-je trouver les coordonnées et autres informations
                  de ma réservation ?
                </h3>
                Vous trouverez l’ensemble des données relatives à votre
                réservation : N° de réservation, nom, prénom, nombre de
                couverts, Restaurant, heure d’arrivée, dans l’email de
                confirmation ou dans votre application mobile. 
              </p>
              <p>
                <h3>
                  6. Comment puis-je laisser un commentaire concernant un
                  établissement ?
                </h3>
                Nous vous donnons rendez-vous sur notre page Facebook ou via
                l’email rating@restolastminute.com. N’hésitez pas à nous faire part
                de votre expérience afin que nous puissions continuer à
                améliorer notre service.
              </p>
              <p>
                <h3>
                  7. Je suis restaurateur et j’aimerais m’inscrire sur
                  restolastminute.com ?
                </h3>
                Pour en savoir plus sur les conditions d’adhésion, nous vous
                invitons à nous contacter par email a hello@restolastminute.pro
              </p>
              <p>
                <h3>
                  8. Avec qui puis-je prendre contact pour une plainte
                  concernant un restaurant ?
                </h3>
                Si vous souhaitez transmettre une plainte concernant un
                restaurant, prenez contact par email hello@restolastminute.com avec
                notre équipe. Mentionnez le nom du restaurant, la date de votre
                visite, et votre numéro de réservation. Toutes les plaintes font
                l’objet d’une enquête auprès du restaurant concerné.
              </p>
            </Typography>
          </div>
        ) : (
          <div className={classes.contentSection}>
            <Typography>
              <p>
                <h3>1. Hoe werkt het?</h3>
                RestoLastMinute is een gratis dienst voor het online boeken van een
                restaurant voor de avond zelf. Elke dag zetten onze
                partnerrestaurants plaatsen met een korting op de rekening ter
                beschikking.
              </p>
              <p>
                4 stappen om te reserveren en de avond zelf te genieten :
                <ul>
                  <li>1. Kies (via de zoekfunctie) een restaurant.</li>
                  <li>
                    Reserveer door het volgende in te vullen :
                    <ul>
                      <li> Uw gegevens, </li>
                      <li>
                        Het aantal couverts in functie van de beschikbaarheid,
                      </li>
                      <li>Het uur van aankomst.</li>
                    </ul>
                  </li>
                  <li>
                    3. Bevestig uw bestelling door op BOOK te klikken. U krijgt
                    onmiddellijk een bevestiging via e-mail en/of via App met uw
                    reservatiegegevens en uw reservatienummer.
                  </li>
                  <li>
                    4. Vertoon als u in het restaurant aankomt uw
                    bevestigingse-mail en geef duidelijk aan dat u via
                    RestoLastMinute.com reserveerde.
                  </li>
                </ul>
              </p>
              <p>
                <h3>2. Werd mijn reservatie goed bevestigd?</h3>
                Iedere reservatie op RestoLastMinute.com wordt onmiddellijk
                bevestigd in een e-mail en/of App die naar u wordt verstuurd.
                Indien u enig probleem ondervindt om onze e-mails of
                tekstberichten te ontvangen neem dan contact met ons op via
                hello@restolastminute.com.
              </p>
              <p>
                <h3>3. Hoe kan ik mijn reservatie annuleren?</h3>
                Het volstaat om op de annulatieknop de klikken. De
                restauranthouder wordt onmiddellijk op de hoogte gebracht.
              </p>
              <p>
                <h3>4. Is het systeem gratis?</h3>
                RestoLastMinute.com is volledig gratis dienst die werd ontwikkeld om
                uw online reservatie te vergemakkelijken. Ons systeem wordt
                gefinancierd door onze partnerrestaurants door middel van een
                maandelijks abonnement. We vragen geen enkele bijkomstige
                commissie aan restauranthouders. Restauranthouders betalen
                simpelweg een maandelijks abonnement en zijn vervolgens helemaal
                vrij om het soort promoties en het aantal plaatsen dat ze op ons
                platform zetten te bepalen. Dankzij dit ethisch verantwoord
                economisch model betalen onze partners geen extra kost en zijn
                ze vrij om de promotie en het aantal beschikbare plaatsen te
                bepalen.
              </p>
              <p>
                <h3>
                  5. Waar kan ik de informatie omtrent mijn boeking terugvinden?
                </h3>
                U vind uw boekingsgegevens : reservatienummer, naam, voornaam,
                aantal couverts, restaurant, uur van aankomst in de e-mail die u
                ter bevestiging werd opgestuurd.
              </p>
              <p>
                <h3>
                  6. Hoe kan ik commentaar achterlaten over een restaurant?
                </h3>
                Afspraak op onze Facebookpagina. Aarzel niet om uw ervaring met
                anderen te delen opdat we onze dienst kunnen verbeteren.
              </p>
              <p>
                <h3>
                  7. Ik ben eigenaar van een restaurant en wil me als partner
                  bij RestoLastMinute.com aansluiten
                </h3>
                Voor meer informatie over aansluiting nodigen we u uit om de
                hiertoe bestemde rubriek voor restauranthouders genaamd « Uw
                Resto Aanbevelen » te raadplegen en het formulier in te vullen
                om zo snel mogelijk gecontacteerd te worden door één van onze
                medewerkers.
              </p>
              <p>
                <h3>
                  8. Met wie moet ik contact opnemen om een klacht in te dienen
                  over een restaurant ?
                </h3>
                Indien u een klacht wil indienen m.b.t. een restaurant, neem
                contact op met ons via e-mail hello@restolastminute.com. Vermeld de
                naam van het restaurant, de datum van uw boeking en uw
                reservatienummer. Alle klachten zullen bij het restaurant zelf
                onderzocht worden door ons team.
              </p>
            </Typography>
          </div>
        )}

        <Footer locale={locale} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locale } = state;

  const { lang } = locale;

  return {
    lang,
    locale: locale.locale
  };
};

export default withRouter(
  connect(mapStateToProps)(withRoot(withStyles(styles)(FaqPage)))
);
