import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Restaurant from "../components/Restaurant";
import Pagination from "material-ui-flat-pagination";
import queryString from "query-string";

import SearchBar from "../components/SearchBar";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Paper,
  GridList,
  GridListTile,
  AppBar,
  Toolbar,
  IconButton,
  Link
} from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import withRoot from "../app/withRoot";
import Footer from "../components/Footer";
import LocaleMenu from "../components/LocaleMenu";
import { FormattedMessage } from "react-intl";
import ReservationModal from "./ReservationModal";
import TopBar from "../components/TopBar";
import ScrollToTop from "../components/ScrollToTop";
import { fetchRestaurantsIfNeeded } from "../actions/restaurants";
import { updateLocaleIfneeded } from "../actions/locale";

const styles = theme => ({
  root: {
    overflowX: "hidden"
  },
  searchSection: {
    backgroundImage: `url("top.jpg")`,
    backgroundSize: "cover",

    color: theme.palette.common.white
  },
  restaurantsSection: {
    padding: theme.spacing.unit * 5
  },
  textEmpty: {
    marginTop: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 20
  }
});

class RestaurantsPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    position: PropTypes.object.isRequired,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    restaurants: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { dispatch, limit } = this.props;
    const position =
      queryString.parse(this.props.location.search).location ||
      this.props.position;
    const searchQuery = queryString.parse(this.props.location.search).search;
    const offset =
      (parseInt(queryString.parse(this.props.location.search).page) - 1 || 0) *
      limit;

    dispatch(
      fetchRestaurantsIfNeeded(
        position,
        offset,
        searchQuery,
        queryString.parse(this.props.location.search).location !== undefined
          ? 20
          : 10000
      )
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const { dispatch, limit } = this.props;
      const searchQuery = queryString.parse(this.props.location.search).search;
      const position =
        queryString.parse(this.props.location.search).location ||
        this.props.position;
      const offset =
        (parseInt(queryString.parse(this.props.location.search).page) - 1 ||
          0) * limit;

      dispatch(
        fetchRestaurantsIfNeeded(
          position,
          offset,
          searchQuery,
          queryString.parse(this.props.location.search).location !== undefined
            ? 20
            : 10000
        )
      );
    }
  }

  handleSearchClick = query => {
    if (query) {
      this.props.history.replace(`/restaurants?search=${query}`);
    }
  };

  handleLocaleClick = lang => {
    this.props.dispatch(updateLocaleIfneeded(lang));
  };

  handlePageClick = offset => {
    const position =
      queryString.parse(this.props.location.search).location ||
      this.props.position;
    const { dispatch, limit } = this.props;

    this.props.history.push({
      pathname: "/restaurants",
      search: `${
        queryString.parse(this.props.location.search).location !== undefined
          ? `?location=${position}&`
          : "?"
      }page=${offset / limit + 1}`
    });
    // dispatch(fetchRestaurantsIfNeeded(position, offset))
  };

  render() {
    const {
      position,
      offset,
      limit,
      count,
      restaurants,
      isFetching,
      classes,
      locale
    } = this.props;

    const isEmpty = restaurants.length === 0;

    return (
      <div className={classes.root}>
        <ScrollToTop />
        <TopBar
          onSearchClick={this.handleSearchClick}
          location={position.address}
          onLocaleClick={this.handleLocaleClick}
          locale={locale}
        />
        <main className={classes.main}>
          <Grid
            className={classes.restaurantsSection}
            container
            spacing={40}
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <Grid
                container
                spacing={40}
                justify="center"
                alignItems="center"
                alignContent="center"
              >
                {isFetching ? (
                  <CircularProgress />
                ) : isEmpty ? (
                  <Typography
                    variant="h4"
                    className={classes.textEmpty}
                    color="textSecondary"
                    align="center"
                  >
                    <FormattedMessage id="restaurants.empty" />
                  </Typography>
                ) : (
                  restaurants.map(e => (
                    <Grid item key={e.id}>
                      <Restaurant restaurant={e} key={e.id} />
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
            {limit < count ? (
              <Grid item>
                <Pagination
                  limit={limit}
                  offset={offset}
                  total={count}
                  onClick={(e, offset) => this.handlePageClick(offset)}
                />
              </Grid>
            ) : null}
          </Grid>
        </main>
        <Footer locale={locale} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { restaurants, locale } = state;

  const { isFetching, items, offset, limit, count } = restaurants;

  return {
    position: locale.position.query,
    restaurants: items,
    offset,
    limit,
    count,
    isFetching,
    locale: locale.locale,
    lang: locale.lang
  };
};

export default withRouter(
  connect(mapStateToProps)(withRoot(withStyles(styles)(RestaurantsPage)))
);
