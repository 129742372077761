import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Link, Paper } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import { FormattedMessage } from "../../node_modules/react-intl";
import { Link as RouterLink } from "react-router-dom";

import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { LOCALES } from "../config";

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 5,
    marginTop: theme.spacing.unit * 5,
  },
});

const Footer = ({ classes, locale }) => {
  return (
    <div component="footer" className={classes.root}>
      <Grid container direction="column" spacing={16} alignItems="center">
        <Grid container direction="row" spacing={40} justify="space-between">
          <Grid item>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                {LOCALES[locale].cities.map((city) => (
                  <Typography>
                    <Link
                      component={RouterLink}
                      title={city}
                      to={`/restaurants?location=${city}`}
                    >
                      <FormattedMessage id={city} />
                    </Link>
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Typography>
                  <Link component={RouterLink} to="/restaurants">
                    <FormattedMessage id="footer.restaurants" />
                  </Link>
                </Typography>
                <Typography>
                  <Link component={RouterLink} to="/usage">
                    <FormattedMessage id="footer.cgu" />
                  </Link>
                </Typography>
                <Typography>
                  <Link component={RouterLink} to="/disclaimer">
                    <FormattedMessage id="footer.privacy" />
                  </Link>
                </Typography>
                <Typography>
                  <Link component={RouterLink} to="/about">
                    <FormattedMessage id="footer.about" />
                  </Link>
                </Typography>
                <Typography>
                  <Link
                    href={LOCALES[locale].b2b}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="footer.restaurateur" />
                  </Link>
                </Typography>
                <Typography>
                  <Link
                    href={`mailto:${LOCALES[locale].mailto}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="footer.contactUs" />
                  </Link>
                </Typography>
                <Typography>
                  <Link component={RouterLink} to="/faq">
                    FAQ
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid item>
              <a
                href="https://play.google.com/store/apps/details?id=com.dreambytes.restolastminute"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/play_store.svg" alt="play store" width={160} />
              </a>
            </Grid>
            <Grid item>
              <a
                href="https://itunes.apple.com/be/app/restolastminute/id913177922?mt=8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/app_store.svg" alt="play store" width={160} />
              </a>
            </Grid>
          </Grid>

          <Grid item style={{ maxWidth: 300 }}>
            <Typography variant="body1" paragraph>
              <FormattedMessage id="footer.aboutText" />
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={16}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                <Link
                  href={LOCALES[locale].facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <Link
                  href={LOCALES[locale].instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textSecondary" component="p">
            SP Management SRL - BE0663782282 - Copyright 2023 RestoLastMinute©
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
