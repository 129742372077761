import { combineReducers } from "redux";

import messages from "../messages.json";
import {
  RESTAURANTS_REQUEST,
  RESTAURANTS_FAILURE,
  RESTAURANTS_SUCCESS
} from "../actions/restaurants.js";
import { LOCALE_UPDATE } from "../actions/locale.js";
import { DEALS_REQUEST, DEALS_SUCCESS } from "../actions/deals.js";
import {
  RESERVATION_POST_REQUEST,
  RESERVATION_POST_SUCCESS,
  RESERVATION_POST_FAILURE
} from "../actions/reservation.js";
import { DEAL_REQUEST, DEAL_SUCCESS, DEAL_SELECT } from "../actions/deal.js";
import {
  RESTAURANT_REQUEST,
  RESTAURANT_SUCCESS,
  RESTAURANT_SELECT
} from "../actions/restaurant.js";
import { DEFAULT_LOCALE } from "../config/index.js";

const locale = (
  state = { locale: DEFAULT_LOCALE.locale, position: DEFAULT_LOCALE.position },
  action
) => {
  switch (action.type) {
    case LOCALE_UPDATE:
      return {
        locale: action.locale,
        lang: action.locale.slice(-2),
        position: action.position,
        messages: {
          ...messages[action.locale.slice(-2)],
          ...messages[action.locale]
        }
      };
    default:
      return {
        ...state,
        lang: state.locale.slice(-2),
        messages: {
          ...messages[state.locale.slice(-2)],
          ...messages[state.locale]
        }
      };
  }
};

const restaurants = (
  state = {
    isFetching: false,
    items: [],
    count: 0,
    offset: 0,
    limit: 50
  },
  action
) => {
  switch (action.type) {
    case RESTAURANTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        offset: action.offset,
        limit: action.limit
      };
    case RESTAURANTS_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case RESTAURANTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        offset: action.offset,
        limit: action.limit,
        count: action.count,
        items: action.restaurants
      };
    default:
      return state;
  }
};

const deals = (
  state = {
    isFetching: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case DEALS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case DEALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.deals
      };
    default:
      return state;
  }
};

const reservation = (
  state = {
    isFetching: false,
    item: null,
    error: null
  },
  action
) => {
  switch (action.type) {
    case RESERVATION_POST_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case RESERVATION_POST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: action.reservation
      };
    case RESERVATION_POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

const deal = (
  state = {
    isFetching: false,
    item: {}
  },
  action
) => {
  switch (action.type) {
    case DEAL_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case DEAL_SUCCESS:
    case DEAL_SELECT:
      return {
        ...state,
        isFetching: false,
        item: action.deal
      };
    default:
      return state;
  }
};

const restaurant = (
  state = {
    isFetching: false,
    didInvalidate: true,
    item: {}
  },
  action
) => {
  switch (action.type) {
    case RESTAURANT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case RESTAURANT_SUCCESS:
    case RESTAURANT_SELECT:
      return {
        ...state,
        isFetching: false,
        item: action.restaurant
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  locale,
  deals,
  deal,
  restaurants,
  restaurant,
  reservation
});

export default rootReducer;
