import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  InputBase,
  Fade,
  Link,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import withRoot from "../app/withRoot";
import { withStyles } from "@material-ui/styles";
import { SearchIcon } from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import SearchBar from "../components/SearchBar";
import { Link as RouterLink } from "react-router-dom";
import Deal from "../components/Deal";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import ScrollToTop from "../components/ScrollToTop";
import CloseIcon from "@material-ui/icons/Close";
import RestaurantDetails from "../components/RestaurantDetails";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { updateLocaleIfneeded } from "../actions/locale";
import { fetchRestaurantIfNeeded } from "../actions/restaurant";
import {
  postReservation,
  postReservationRequest
} from "../actions/reservation";
import queryString from "query-string";

const styles = theme => ({
  root: {
    overflowX: "hidden"
  },
  mapSection: {
    position: "relative"
  },
  mapImg: {
    width: "100%"
  },
  mapOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    background:
      "linear-gradient(to top, rgba(0,0,0,0.4) 0%, " +
      "rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%)",
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit * 10
  },
  dealSection: {
    padding: theme.spacing.unit * 5
  }
});

class RestaurantPage extends Component {
  static propTypes = {
    position: PropTypes.object.isRequired,
    restaurant: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    reservation: PropTypes.object
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchRestaurantIfNeeded(parseFloat(this.props.match.params.id)));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      const { dispatch } = this.props;
      dispatch(fetchRestaurantIfNeeded(this.props.match.params.id));
    }
  }

  handleSearchClick = query => {
    if (query) {
      this.props.history.push(`/restaurants?search=${query}`);
    }
  };

  handleLocaleClick = newLocale => {
    const { dispatch } = this.props;
    dispatch(updateLocaleIfneeded(newLocale));
  };

  render() {
    const {
      position,
      restaurant,
      isFetching,
      classes,
      lang,
      reservation,
      locale
    } = this.props;

    const handleReservationClick = reservation => {
      if (restaurant.deal) {
        this.props.dispatch(postReservation(reservation));
      } else {
        this.props.dispatch(postReservationRequest(reservation));
      }
    };

    const isWebview =
      queryString.parse(this.props.location.search, { parseBooleans: true })
        .webview || false;

    return (
      <div className={classes.root}>
        <ScrollToTop />
        {!isWebview && (
          <TopBar
            onSearchClick={this.handleSearchClick}
            location={position.address}
            onLocaleClick={this.handleLocaleClick}
            locale={locale}
          />
        )}
        <main className={classes.main}>
          <section className={classes.mapSection} />
          <section className={classes.dealSection}>
            {isFetching ? (
              <CircularProgress />
            ) : !restaurant ? (
              <h2>No content</h2>
            ) : (
              <div>
                <Helmet>
                  <title>{restaurant.name}</title>
                  <FormattedMessage
                    id="restaurant.description"
                    values={{
                      name: restaurant.name,
                      city: restaurant.city
                    }}
                  >
                    {message => <meta name="description" content={message} />}
                  </FormattedMessage>
                </Helmet>
                <RestaurantDetails
                  restaurant={restaurant}
                  lang={lang}
                  reservation={reservation}
                  onReservationClick={handleReservationClick}
                />
              </div>
            )}
          </section>
        </main>
        {!isWebview && <Footer locale={locale} />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isFetching, item } = state.restaurant;
  const { locale, reservation } = state;

  return {
    position: locale.position.query,
    restaurant: item,
    isFetching,
    lang: locale.lang,
    locale: locale.locale,
    reservation
  };
};

export default withRouter(
  connect(mapStateToProps)(withRoot(withStyles(styles)(RestaurantPage)))
);
