import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
  withStyles,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { FormattedMessage } from "react-intl";
import isBefore from "date-fns/is_before";
import slugify from "@sindresorhus/slugify";

const styles = (theme) => ({
  root: {
    minHeight: 200,
    minWidth: 100,
    width: 360,
  },
  card: {},
  media: {
    position: "relative",
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  percentageOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 0 0 10%",
    color: theme.palette.common.white,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
  },
  content: {},
});

const Deal = ({ deal, classes, webview }) => {
  const {
    id,
    name,
    city,
    cuisines,
    pictureUrl,
    percentage,
    isNew,
    availablePlaces,
    endBookingHour,
    endBookingMinute,
    restaurantId,
    pause,
  } = deal;

  const today = new Date();
  const endBookingDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    deal.dinner ? deal.endBookingHour : 13,
    deal.dinner ? deal.endBookingMinute : 1
  );

  return (
    <div className={classes.root}>
      <Card elevation={0} className={classes.card}>
        <CardActionArea
          component={Link}
          to={`/restaurants/${restaurantId}/${slugify(name)}${
            webview ? "?webview=1" : ""
          }`}
        >
          <CardMedia
            className={classes.media}
            image={pictureUrl}
            title={name}
          />
          {percentage ? (
            <div className={classes.percentageOverlay}>
              <Typography
                variant={percentage >= 50 ? "h4" : "h6"}
                color="inherit"
              >
                -{percentage}%
              </Typography>
            </div>
          ) : null}
          <CardContent className={classes.content}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="subtitle1">
              {city} -{" "}
              {pause ? (
                <Typography color="primary" variant="subtitle1" inline>
                  <FormattedMessage id="deal.pause" />
                </Typography>
              ) : availablePlaces > 0 && isBefore(today, endBookingDate) ? (
                <span>
                  {deal.lunch && <FormattedMessage id="deal.lunch" />}
                  {deal.dinner && (
                    <Fragment>
                      {deal.lunch ? (
                        <Fragment>
                          {" "}
                          <FormattedMessage id="and" />{" "}
                        </Fragment>
                      ) : (
                        " "
                      )}
                      <FormattedMessage id="deal.dinner" />
                    </Fragment>
                  )}
                  {" - "}
                  <FormattedMessage
                    id="deal.availablePlaces"
                    values={{ availablePlaces: availablePlaces }}
                  />{" "}
                </span>
              ) : (
                <Typography color="primary" variant="subtitle1" inline>
                  <FormattedMessage id="deal.noMorePlaces" />
                </Typography>
              )}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

Deal.propTypes = {
  deal: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    pictureUrl: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    cuisines: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  webview: PropTypes.bool,
};

export default withStyles(styles)(Deal);
