import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
  withStyles,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { FormattedMessage } from "react-intl";
import isBefore from "date-fns/is_before";
import slugify from "@sindresorhus/slugify";

const styles = (theme) => ({
  root: {
    width: 360,
  },
  media: {
    position: "relative",
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  percentageOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 0 0 10%",
    color: theme.palette.common.white,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
  },
  content: {},
});

const Restaurant = ({ restaurant, classes }) => {
  const { id, name, city, cuisines, pictureUrl } = restaurant;

  const today = new Date();

  const endBookingDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    restaurant.deal && restaurant.deal.dinner
      ? restaurant.deal.endBookingHour
      : 13,
    restaurant.deal && restaurant.deal.dinner
      ? restaurant.deal.endBookingMinute
      : 1
  );

  return restaurant.deal ? (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={`/restaurants/${id}/${slugify(name)}`}
        >
          <CardMedia
            className={classes.media}
            image={pictureUrl}
            title={name}
          />
          {restaurant.deal.percentage ? (
            <div className={classes.percentageOverlay}>
              <Typography
                variant={restaurant.deal.percentage >= 50 ? "h4" : "h6"}
                color="inherit"
              >
                -{restaurant.deal.percentage}%
              </Typography>
            </div>
          ) : null}
          <CardContent className={classes.content}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="subtitle1">
              {city} -{" "}
              {restaurant.deal.pause ? (
                <Typography color="primary" variant="subtitle1" inline>
                  <FormattedMessage id="deal.pause" />
                </Typography>
              ) : restaurant.deal.availablePlaces > 0 &&
                isBefore(today, endBookingDate) ? (
                <FormattedMessage
                  id="deal.availablePlaces"
                  values={{ availablePlaces: restaurant.deal.availablePlaces }}
                />
              ) : (
                <Typography color="primary" variant="subtitle1" inline>
                  <FormattedMessage id="deal.noMorePlaces" />
                </Typography>
              )}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  ) : (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={`/restaurants/${id}/${slugify(name)}`}
        >
          <CardMedia
            className={classes.media}
            image={pictureUrl}
            title={name}
          />
          <CardContent className={classes.content}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="subtitle1">{city}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

Restaurant.propTypes = {
  restaurant: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    pictureUrl: PropTypes.string.isRequired,
    cuisines: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Restaurant);
