import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withRoot from "../app/withRoot";
import withStyles from "@material-ui/styles/withStyles";
import {
  Grid,
  Typography,
  Button,
  Toolbar,
  Link,
  AppBar,
  Paper
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import LocaleMenu from "../components/LocaleMenu";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import { Link as RouterLink } from "react-router-dom";
import { updateLocaleIfneeded } from "../actions/locale";
import { LOCALES } from "../config";
import TopBar from "../components/TopBar";

const styles = theme => ({
  root: {
    overflowX: "hidden"
  },
  topSection: {
    backgroundImage: `url("top.jpg")`,
    backgroundSize: "cover",

    color: theme.palette.common.white
  },
  contentSection: {
    paddingLeft: "20%",
    paddingRight: "20%",
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 5
  }
});

class UsagePage extends Component {
  handleLocaleClick = lang => {
    this.props.dispatch(updateLocaleIfneeded(lang));
  };

  render() {
    const { classes, lang, locale } = this.props;
    return (
      <div className={classes.root}>
        <ScrollToTop />
        <TopBar
            onLocaleClick={this.handleLocaleClick}
            locale={locale}
          />
        <main className={classes.main} />
        {lang === "fr" ? (
          <div className={classes.contentSection}>
            <Typography>
              <p>
                <strong>ARTICLE 1ER – DEFINITIONS</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>APPLICATION</strong>&nbsp; : le programme informatique
                développé par RESTOLASTMINUTE et accessible sur les boutiques
                d’application APPSTORE et GOOGLE PLAY.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>CONDITIONS D’UTILISATION</strong> : le cadre contractuel
                régissant l’accès au SITE, y compris au niveau du traitement des
                données personnelles et de l’utilisation de cookies et qui, le
                cas échéant, se combine avec les présentes conditions générales
                lorsque l’internaute est ou devient un UTILISATEUR
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>OFFRE PROMOTIONNELLE</strong> : l’offre d’un
                RESTAURATEUR accessible via la PLATE-FORME RESTO MINUTE MANAGER.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>PLATE-FORME RESTO MINUTE MANAGER</strong> : le SITE ou
                l’APPLICATION mis à disposition de l’UTILISATEUR par RESTOLASTMINUTE
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>RESTAURATEUR</strong> : le client professionnel
                recourant aux SERVICES proposés par RESTOLASTMINUTE
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>RESTOLASTMINUTE</strong> : la société privée à
                responsabilité limitée SP Management SRL, dont le siège social est
                établi à 6042 LODELINSART, Chaussée de Châtelet 6 et inscrite à
                la Banque Carrefour des Entreprises sous le numéro
                BE0663782282 (RPM Bruxelles)
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>SERVICE DE RESERVATION</strong> : le service proposé par
                RESTOLASTMINUTE permettant, via la PLATE-FORME, de réserver une
                OFFRE PROMOTIONNELLE auprès d’un RESTAURATEUR dans les
                conditions et limites définies par lui
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>SERVICES</strong> : les services proposés par
                RESTOLASTMINUTE au RESTAURATEUR
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>SITE</strong> : le site internet&nbsp;
                <a href="http://www.restolastminute.com/">
                  http://www.restolastminute.com/
                </a>&nbsp;
                permettant de réserver.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;
              </p>
              <p>
                <strong>UTILISATEUR</strong> : toute personne majeure qui
                utilise le SERVICE DE RESERVATION de RESTOLASTMINUTE
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <p>
                <strong>ARTICLE 2 – CHAMP D’APPLICATION</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                Les présentes conditions générales forment, avec les CONDITIONS
                D’UTILISATION et les éventuelles conditions spécifiques
                mentionnées sur la PLATE-FORME, l’intégralité du cadre
                contractuel convenu entre RESTOLASTMINUTE et l’UTILISATEUR
                (dénommé(e) ensemble les « PARTIES ») et s’appliquent à toutes
                les relations nées ou découlant de l’utilisation de la
                PLATE-FORME, du SERVICE DE RESERVATION, de leurs suites et
                conséquences.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <p>
                <strong>ARTICLE 3 – SERVICE DE RESERVATION</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                Le SERVICE DE RESERVATION a pour but de permettre à l’UTILSATEUR
                de réserver, via la PLATE-FORME, une OFFRE PROMOTIONNELLE aux
                conditions et limites définies par le RESTAURATEUR et d’en
                confirmer, en fonction des conditions et disponibilités propres
                à l’OFFRE PROMOTIONNELLE, la réservation à l’UTILISATEUR au nom
                et pour le compte du RESTAURATEUR.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>ARTICLE 4 – RÔLE DE RESTOLASTMINUTE</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                RESTOLASTMINUTE centralise et diffuse les OFFRES PROMOTIONNELLES
                transmises par les RESTAURATEURS et les met à disposition des
                UTILISATEURS au travers de la PLATE-FORME. RESTOLASTMINUTE n’assume
                aucune responsabilité quant au service offert par le
                RESTAURATEUR et il appartient à l’UTILISATEUR de lire
                attentivement les conditions de l’OFFRE PROMOTIONNELLE du
                RESTAURATEUR et notamment les jours applicables, le nombre de
                couverts, le taux de réduction, le ou les plats/menus visés par
                la promotion ainsi que la manière dont elle sera calculée. Sauf
                mention contraire dans l’OFFRE PROMOTIONNELLE, elle s’applique
                sur le plat principal et, optionnellement, l’entrée et le dessert
                commandé(s) pour le nombre de couverts réservés et à l’exclusion
                des apéritifs, boissons, digestifs, plats promotionnels ou
                menus.
              </p>
              <p>&nbsp;</p>
              <p><strong>ARTICLE 5 – RÔLE DE L’UTILISATEUR</strong></p>
              <p>&nbsp;</p>
              <p>
                5.1. L’UTILISATEUR s’engage à utiliser le SERVICE DE RESERVATION
                avec loyauté et bonne foi et à respecter les conditions propres
                à chaque OFFRE PROMOTIONNELLE. L’UTILISATEUR s’interdit
                d’effectuer une réservation inexacte, hypothétique, fausse ou
                frauduleuse ou d’utiliser des données d’identification
                inexactes, fausses ou frauduleuses. L’UTILISATEUR s’engage à
                honorer la réservation en se présentant chez le RESTAURATATEUR
                au jour et à l’heure convenus. RESTOLASTMINUTE est autorisée, en cas
                d’usage non conforme du SERVICE DE RESERVATION, à communiquer
                les coordonnées de l’UTILISATEUR au RESTAURATEUR et/ou au tiers
                (e.a. en cas d’usurpation d’identité) afin que ceux-ci puissent,
                le cas échéant, réclamer les dommages et intérêts (manque à
                gagner, perturbation du service, etc.) et diligenter les
                procédures civiles, administratives ou pénales liées au
                comportement de l’UTILISATEUR sans préjudice des mesures et
                actions pouvant être prises par RESTOLASTMINUTE, telle une exclusion
                de la PLATE-FORME.&nbsp;
              </p>
              <p>&nbsp;</p>
              <p>
                5.2. L’utilisation du SERVICE DE RESERVATION est strictement
                personnelle et ne peut être utilisée à des fins commerciales ou
                spéculatives ou pour en tirer un quelconque avantage autre que
                la promotion associée à une OFFRE PROMOTIONNELLE. L’UTILISATEUR
                ne peut transférer, ni à titre gratuit ni à titre onéreux, la
                réservation ou faire commerce des OFFRES PROMOTIONNELLES ou des
                avantages y associés.
              </p>
              <p>&nbsp;</p>
              <p>
                5.3. Dans l’hypothèse où RESTOLASTMINUTE constate que l’UTILISATEUR
                ne respecte pas les conditions d’utilisation de la PLATE-FORME
                ou du SERVICE DE RESERVATION, elle pourra, sans mise en demeure
                ou intervention judiciaire préalables, exclure l’UTILISATEUR et
                mettre fin aux relations contractuelles sans préjudice des
                dommages et intérêts qu’elle pourrait réclamer à
                l’UTILISATEUR.&nbsp;
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <p>
                <strong>ARTICLE 6 – TRAITEMENT DES DONNÉES PERSONNELLES</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                Pour assurer l’accès à la PLATE-FORME et au SERVICE DE
                RESERVATION, l’UTILISATEUR est amené à communiquer à RESTOLASTMINUTE
                des données personnelles (e.a. identité, âge, adresses postale
                et électronique, téléphone) et pour lesquelles RESTOLASTMINUTE est
                le responsable du traitement au sens de la réglementation sur la
                protection de la vie privée. Ces données personnelles sont
                uniquement communiquées et utilisées pour la bonne exécution des
                SERVICES et du SERVICE DE RESERVATION proposés par RESTOLASTMINUTE,
                ses suites et conséquences et, le cas échéant, pour des services
                annexes de RESTOMINIUTE ou de partenaires lorsque
                l’UTILISATEUR y a consenti. Les objectifs, finalités, limites et
                conditions d’utilisation des données personnelles et les
                possibilités d’y accéder et de les rectifier sont définis dans
                les CONDITIONS D’UTILISATION accessibles ICI et que
                l’UTILISATEUR déclare avoir lues et acceptées et qui font
                parties intégrantes des présentes conditions générales.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>ARTICLE 7 – DROITS DE PROPRIÉTÉ INTELLECTUELLE</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                La PLATE-FORME et son contenu (notamment les images, logos, et
                textes) sont protégés par des droits de propriété
                intellectuelle, y compris le droit d’auteur, qui demeurent la
                propriété exclusive de RESTOLASTMINUTE et/ou de ses partenaires.
                L’UTILISATEUR s’interdit de copier, modifier, reproduire,
                exploiter ou s’approprier d’une quelconque manière tout ou
                partie de la PLATE-FORME et de son contenu.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>ARTICLE 8 – RESPONSABILITES</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                8.1. Toutes les obligations souscrites par RESTOLASTMINUTE sont
                exclusivement de moyens. RESTOLASTMINUTE ne peut être tenue pour
                responsable des conséquences dommageables découlant d’une
                défaillance, d’une erreur, d’une faute ou d’un retard imputable
                au RESTAURATEUR ou à tout autre tiers.
              </p>
              <p>&nbsp;</p>
              <p>
                8.2. RESTOLASTMINUTE ne peut être tenue pour responsable, tant sur
                le plan contractuel qu’extracontractuel, en cas d’inexécution,
                temporaire ou définitive, de ses obligations lorsque cette
                inexécution résulte d’un cas de force majeure ou fortuit. Sont
                notamment considérés comme des cas de force majeure ou fortuits,
                les événements suivants : 1) les incendies, 2) les inondations,
                3) les actes de guerre ou de terrorisme, 4) les grèves,
                déclarées ou non, 5) les lock-out, 6) un arrêt de fourniture
                d’énergie (telle que l’électricité), 7) une défaillance du
                réseau Internet, 8) une défaillance du réseau de
                télécommunications, 9) un fait ou une décision d’un tiers
                lorsque cette décision affecte la bonne exécution du présent
                contrat ou 10) toute autre cause échappant au contrôle
                raisonnable de RESTOLASTMINUTE, y compris lorsqu’elle cause une
                indisponibilité temporaire ou définitive de la PLATE-FORME et
                des services y proposés.
              </p>
              <p>&nbsp;</p>
              <p>
                8.3. L’effectivité du SERVICE DE RESERVATION suppose que le
                RESTAURATEUR exécute, à l’égard de RESTOLASTMINUTE, ses obligations
                contractuelles de telle sorte que RESTOLASTMINUTE ne peut être
                tenue pour responsable si, en raison d’une défaillance du
                RESTAURATEUR, elle est amenée à stopper, temporairement ou
                définitivement, l’exécution de tout ou partie de ses SERVICES à
                l’égard du RESTAURATEUR provoquant une perturbation du SERVICE
                DE RESERVATION et des réservations en résultant. En telle
                hypothèse, le RESTAURATEUR sera, à l’égard de l’UTILISATEUR,
                seul responsable des conséquences liées à un arrêt temporaire ou
                définitif des SERVICES jusqu’alors proposés par
                RESTOLASTMINUTE.&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p>&nbsp;</p>
              <p>
                8.4. Dans l’hypothèse où les conditions permettant d’engager la
                responsabilité aquilienne ou contractuelle de RESTOLASTMINUTE sont
                démontrées par l’UTILISATEUR, le montant du dommage pouvant être
                réclamé par l’UTILISATEUR (le cas échéant sur une base cumulée
                en cas de pluralité de sinistres) ne peut dépasser 20% du
                montant payé ou à payer au RESTAURATEUR à l’occasion de la
                dernière réservation effectuée par l’UTILISATEUR avec un plafond
                maximum de 100 EUR (cent euros).
              </p>
              <p>&nbsp;</p>
              <p>
                8.5. Toute action en responsabilité contractuelle ou
                extracontractuelle contre RESTOLASTMINUTE, ses agents, préposés,
                collaborateurs indépendants ou mandataires se prescrit par 3
                (trois) ans à compter du jour de la survenance du fait fautif.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>ARTICLE 9 – DISPOSITIONS DIVERSES</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                9.1. RESTOLASTMINUTE pourra transférer tout ou partie des droits et
                obligations résultant des relations nouées avec l’UTILISATEUR à
                un TIERS. L’UTILISATEUR s’interdit de transférer tout ou partie
                des droits et obligations résultant des relations nouées avec
                RESTOLASTMINUTE ou avec un RESTAURATEUR.
              </p>
              <p>&nbsp;</p>
              <p>
                9.2. Si une ou plusieurs clauses des présentes conditions
                générales venaient à être déclarées nulles ou inapplicables, la
                nullité ou l’inapplicabilité ne peut affecter la validité ou
                l’applicabilité des autres clauses. En cas d’annulation, pour
                quelque raison que ce soit, les PARTIES s’engagent à négocier
                immédiatement une nouvelle clause permettant, autant que faire
                se peut, d’aboutir aux effets, notamment économiques, recherchés
                initialement par elles.
              </p>
              <p>&nbsp;</p>
              <p>
                9.3. Le fait que RESTOLASTMINUTE omette, à un moment donné, d’exiger
                la stricte application des dispositions régissant les relations
                contractuelles entretenues avec l’UTILISATEUR, ne peut être
                considéré comme une renonciation aux droits dont elle dispose et
                ne l’empêchera pas d’en exiger la stricte observation.
              </p>
              <p>&nbsp;</p>
              <p>
                9.4.RESTOLASTMINUTE peut adapter et actualiser les présentes
                conditions générales. L’accès à la PLATE-FORME ou l’utilisation
                du SERVICE DE RESERVATION par l’UTILISATEUR présume de son
                accord et de son acceptation des conditions générales
                contemporaines à son accès à la PLATE-FORME ou à l’utilisation
                du SERVICE DE RESERVATION.
              </p>
              <p>&nbsp;</p>
              <p>
                9.5. En cas de contestation, RESTOLASTMINUTE et l’UTILISATEUR
                s’efforceront de trouver une solution amiable. A défaut de
                résolution amiable, le litige sera porté devant les tribunaux de
                l’arrondissement judiciaire au sein duquel est établi son siège
                social ou devant ceux de l’arrondissement judiciaire au sein
                duquel est établi le siège social ou le domicile de
                l’UTILISATEUR. Les relations entre RESTOLASTMINUTE et l’UTILISATEUR
                sont exclusivement régies par le droit belge.
              </p>
            </Typography>
          </div>
        ) : (
          <div className={classes.contentSection}>
            <Typography>
              <p>
                <strong>ARTICLE 1er – DEFINITIES&nbsp;</strong>
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>APPLICATION</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        Het IT-programma ontwikkeld door RESTOLASTMINUTE en zijn
                        toegankelijk op de winkels van APPSTORE en GOOGLES PLAY
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>GEBRUIKS-CONDITIES</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        De contractuele kader inzake de toegang tot de site,
                        waaronder ook het niveau van de verwerking van
                        persoonsgegevens en het gebruik van cookies en , indien
                        nodig, samengevoegd aan de huidige algemene voorwaarden
                        voor nieuwe internetgebruiker of een gebruiker.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>PROMOTIE/AANBIEDING</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        Het aanbieden van een toegankelijke RESTAURANT via het
                        platform&nbsp;
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>PLATEFORM</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        De site of de apps die aan de gebruikers gegeven wordt
                        door RESTOLASTMINUTE
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>RESTAURANT</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        De professionele cliënt die aanspraak doet tot de
                        diensten voorgesteld door RESTOLASTMINUTE
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>RESTOLASTMINUTE</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        De besloten vennootschap met beperkte aansprakelijkheid
                        SP Management SRL, waarvan het hoofdkantoor is gevestigd
                        in 6042 LODELINSART,Chaussée de Châtelet 6 en
                        geregistreerd bij de Bank Carrefour van ondernemingen
                        onder nummer BE0663782282 (RPR Brussel)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>RESERVEREN/BOEKEN</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        De service voorgesteld door RESTOLASTMINUTE, via het
                        platform, om te reserveren , om het boeken van een
                        speciale aanbieding van een restaurant in de voorwaarden
                        en de grenzen die door de restaurant bepaald zijn.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>SERVICES</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        De diensten voorgesteld door RESTOLASTMINUTE aan de
                        restauranthouder
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>SITE</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        Internet
                        <a href="http://www.restolastminute.com/">
                          http://www.restolastminute.com/
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>GEBRUIKER</p>
                    </td>
                    <td>
                      <p>:</p>
                    </td>
                    <td>
                      <p>
                        Elke meerdejarige die het service van reserveren van
                        RESTOLASTMINUTE gebruikt
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <strong>ARTICLE 2 – TOEPASSINGSGEBIED</strong>
              </p>
              <p>
                De onderhavige algemene voorwaarden vormen samen met de
                gebruiksvoorwaarden en eventuele bijzondere voorwaarden vermeld
                op het platform, de volledigheid van de contractuele kader
                afgestemd tussen RESTOLASTMINUTE en de gebruiker (samen “de
                partijen") en gelden voor alle geboren betrekkingen of
                voortvloeiend uit het gebruik van het platform, de
                reserveringsservice, hun gevolgen en de consequenties.
              </p>
              <p>
                <strong>ARTICLE 3 – RESERVERINGSSYSTEEM</strong>
              </p>
              <p>
                De reserveringsservice heeft als doel de Gebruikers toe te laten
                om te reserveren via het platform, een speciale aanbieding onder
                de voorwaarden en de grenzen die zijn bepaald door de restaurant
                en te bevestigen, volgens de voorwaarden en beschikbaarheden
                gedefinieerd door de restaurant, in naam van de restaurant.
              </p>
              <p>
                <strong>ARTICLE 4 – ROL VAN RESTOLASTMINUTE</strong>
              </p>
              <p>
                RESTOLASTMINUTE centraliseert en zendt de aanbiedingen uit die door
                de restaurateurs worden gedéfinieerd en geeft ze aan gebruikers
                terug via het platform. RESTOLASTMINUTE neemt geen
                verantwoordelijkheid voor de service aangeboden door de
                restauranthouder en het is aan de gebruiker om aandachtig de
                voorwaarden van de aanbieding van de restaurateur te lezen , met
                name de geldende dagen, het aantal vrije plaatsen, het
                percentage van vermindering, één of meerdere gerechten / menu's
                gericht door de promotie evenals de manier waarop ze wordt
                berekend. Behalve tegengestelde vermelding in de aanbieding, de
                promotie wordt berekend op het hoofdgerecht en, indien nodig, de
                voorgerecht en de dessert voor het aantal gereserveerde plaatsen
                met uitzondering van aperitieven, dranken, likeuren, interne
                promoties.
              </p>
              <p>
                <strong>ARTICLE 5&nbsp;– ROL VAN DE GEBRUIKER</strong>
              </p>
              <p>
                <strong>
                  5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                De gebruiker maakt een keuze om de reserveringsservice te
                gebruiken met loyaliteit en vertrouwen en respect te hebben voor
                de passende voorwaarden van elke aanbieding. De gebruiker zal
                geen onnauwkeurige, hypothetisch, valse of frauduleuze
                reservering maken of gebruik maken van onnauwkeurig, valse of
                frauduleuze gegevens voor identificatie.
              </p>
              <p>
                De gebruiker verplicht zich te houden aan zijn reservering en
                zich te melden in het RESATAURANT&nbsp; in de dag en op het
                afgesproken uur. RESTOLASTMINUTE is gemachtigd om, in geval van niet
                aangepast gebruik van de reserveringsservice, de adres en
                telefoonnummer van de gebruiker te communiceren aan het
                RESTAURANT en/of aan derden (e.a. in geval van fraude) zodat
                deze kunnen, indien nodig, een aanvraag doen voor de schade
                (verlies van inkomsten, serviceonderbreking, etc.) en/of een
                burgerlijke, administratieve of strafrechtelijke procedures
                starten , en dit allen onverminderd met de maatregelen en acties
                die kunnen worden genomen door RESTOLASTMINUTE, zoals een
                uitsluiting/schorsing van het platform.
              </p>
              <p>
                <strong>5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>Het gebruik
                van de reserveringsservice is strikt persoonlijk en kan niet
                worden gebruikt voor commerciële of speculatieve doeleinden of
                om eender welk ander voordeel, ander dan de promotie gebonden
                aan de aanbieding. De gebruiker kan de aanbiedingen of
                reservering niet overdragen aan derden, noch gratis noch tegen
                een vergoeding.
              </p>
              <p>
                <strong>
                  5.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                In de hypothese waarin RESTOLASTMINUTE merkt dat de gebruiker zich
                niet toehoudt aan de voorwaarden voor het gebruik van het
                platform of de reserveringsservice, kan zij zonder voorafgaande
                gerechtelijke ingebrekestelling of tussenkomst de gebruiker
                uitsluiten en een einde zetten aan de contractuele relaties,
                onverminderd de schadevergoeding die RESTOLASTMINUTE kan vragen ten
                opzichte van de gebruiker.
              </p>
              <p>
                <strong>
                  ARTICLE 6&nbsp;– VERWERKING VAN PERSOONSGEGEVENS
                </strong>
              </p>
              <p>
                Om toegang te hebben tot het platform en reserveringsdienst moet
                de gebruiker persoonsgegevens (o.a.. identiteit, leeftijd,
                e-mail en elektronische adressen, telefoon)&nbsp; aan
                RESTOLASTMINUTE communiceren en waarvoor RESTOLASTMINUTE de
                verantwoordelijk is voor de behandeling in de zin van de
                wetgeving betreffende de bescherming van het privé-leven.
              </p>
              <p>
                Deze persoonsgegevens worden uitsluitend verstrekt en gebruikt
                voor de goede uitvoering van de diensten en de
                reserveringsservice aangeboden door RESTOLASTMINUTE, en de gevolgen
                daarvan, alsmede, zo nodig, voor bijkomende diensten van
                RESTOLASTMINUTE of partners als de gebruiker daarmee heeft
                ingestemd.
              </p>
              <p>
                De doelstellingen, de uiteinden, de grenzen en de voorwaarden
                voor het gebruik van de persoonsgegevens en de mogelijkheden om
                ze te bereiken en veranderen , worden nader omschreven in de
                gebruiksvoorwaarden toegankelijke HIER en dat de gebruiker
                verklaart te hebben gelezen en aanvaard en die integraal deel
                uitmaken van algemene voorwaarden.
              </p>
              <p>
                <strong>ARTICLE 7&nbsp;– INTELLECTUELE EIGENDOMSRECHTEN</strong>
              </p>
              <p>
                Het platform en de inhoud ervan (met name de beelden, de logo's
                en teksten) zijn beschermd door intellectuele eigendomsrechten,
                waaronder het auteursrecht, deze blijven exclusief eigendom van
                RESTOLASTMINUTE en/of zijn partners. De gebruiker onthoudt zich
                ervan te kopiëren, te wijzigen, te reproduceren, gebruik te
                maken van enigerlei wijze het geheel of een gedeelte van het
                platform en zijn inhoud.
              </p>
              <p>
                <strong>ARTICLE&nbsp;8&nbsp;– VERANTWOORDELIJKHEDEN</strong>
              </p>
              <p>
                <strong>
                  8.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                Alle verplichtingen ondertekend door RESTOLASTMINUTE zijn
                uitsluitend van middelen. RESTOLASTMINUTE kan niet aansprakelijk
                zijn voor schadelijke gevolgen die voortvloeien uit het
                ontbreken, foutieve communicatie , een probleem of van een
                vertraging die toerekenbaar is aan de restaurant of tot
                derden.&nbsp;
              </p>
              <p>
                <strong>
                  8.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                &nbsp; RESTOLASTMINUTE kan niet aansprakelijk worden gesteld, zowel
                op de contractuele plan en de extracontractuele plan, in geval
                van niet leveren , tijdelijk of definitief van zijn
                verplichtingen wanneer deze niet leveren het gevolg is van een
                geval van absolute noodzaak of toevallig.
              </p>
              <p>
                De volgende gebeurtenissen worden geacht als bijzonder of
                gevallen van absolute noodzaak of toevallige: 1) de brand, 2) de
                overstromingen, 3) het gevolg van oorlogshandelingen en
                terrorisme, 4) de staking, verklaarde of niet, 5) blokkering, 6)
                een storing van levering van energie (zoals de elektriciteit),
                7) een storing van het Internet netwerk, 8) een mislukking van
                het telecommunicatienetwerk, 9) een besluit van een derde als
                dit besluit gevolgen heeft op de goede uitvoering van het
                huidige contract of 10) enige andere oorzaak die buiten de
                redelijke controle van RESTOLASTMINUTE valt, al heeft dit tot gevold
                een tijdelijke of definitieve uitsluiting van het platform en de
                voorgestelde diensten.
              </p>
              <p>
                <strong>
                  8.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                De effectiviteit van de reserveringsservice veronderstelt dat de
                restaurant ten opzichte van RESTOLASTMINUTE, zijn contractuele
                verplichtingen volgt zodat RESTOLASTMINUTE niet aansprakelijk kan
                worden gesteld als gevolg van een slechte uitvoering van de
                restaurant, die als gevolg een tijdelijk of definitief
                stopzetting, een slechte uitvoering van alle of een deel van
                haar diensten naar de restaurant , een serviceonderbreking zou
                hebben&nbsp; . In deze hypothese, de restaurant ten opzichte van
                de gebruiker, zal de enige verantwoordelijk zijn van tijdelijke
                of definitieve stopzetting van de services van
                RESTOLASTMINUTE.&nbsp;&nbsp;
              </p>
              <p>
                <strong>
                  8.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>{" "}
                In de hypothese waarbij de verantwoordelijkheid van
                onnrechtmatige daad of contractueel van RESTOLASTMINUTE
                gedemonstreerd kan zijn door de gebruiker, zal het bedrag van de
                schade die kan worden gevraagd door de gebruiker (indien nodig
                op een cumulatieve wijze in geval van pluraliteit van rampen)
                geen 20% overschrijden van het betaalde bedrag of nog te betalen
                bedrag aan de restaurant ter gelegenheid van de laatste
                reserveringen door de gebruiker met een maximum van 100 EUR .
              </p>
              <p>
                <strong>
                  8.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                Elke actie in contractuele of buitencontractuele
                aansprakelijkheid tegen RESTOLASTMINUTE, zijn agenten, werknemers,
                onafhankelijken of medewerknemers vervalt per 3 ( drie) jaar
                vanaf de datum van het ontstaan van de schuldige daad.
              </p>
              <p>
                <strong>ARTICLE 9&nbsp;– DIVERSE BEPALINGEN</strong>
              </p>
              <p>
                <strong>
                  9.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                RESTOLASTMINUTE kan de gehele of gedeelte van de rechten en plichten
                die voortvloeien uit de relatie met de gebruiker aan een derde
                overdragen. De gebruiker onthoudt zich voor de gehele of
                gedeeltelijke overdracht van de rechten en plichten die
                voortvloeien uit de relatie met RESTOLASTMINUTE of met een
                restaurateur.
              </p>
              <p>
                <strong>
                  9.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                Indien één of meer bepalingen van deze algemene voorwaarden
                ongeldig worden verklaard of niet van toepassing zijn, kan de
                nietigheid of de ongeldigheid geen invloed hebben op de
                geldigheid of toepasbaarheid van de andere bepalingen. In geval
                van annulering, om welke reden dan ook, zullen de partijen
                onmiddellijk onderhandelen over een nieuwe bepaling die, voor
                zover mogelijk, dezelfde effecten, met name economische, zullen
                hebben.
              </p>
              <p>
                <strong>9.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                Het feit dat RESTOLASTMINUTE negeert, bij sommigen vereisen om de
                strikte toepassing inzake de contractuele eisen toe te passen
                aan de gebruikers, kan niet worden beschouwd als een verzaking
                aan de rechten die RESTOLASTMINUTE heeft en zal RESTOLASTMINUTE niet
                tegenhouden om deze eisen dwingend toe te passen.&nbsp;
              </p>
              <p>
                <strong>
                  9.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                RESTOLASTMINUTE kan de huidige algemene voorwaarden aanpassen. De
                toegang tot het platform of het gebruik van de reservering
                service door de gebruiker verondersteld akkoord en aanvaarding
                van de hedendaagse algemene voorwaarden, dus op datum van de
                toegang tot het platform of het gebruik van de
                reserveringsservice.
              </p>
              <p>
                <strong>
                  9.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </strong>
                In geval van betwisting, RESTOLASTMINUTE en de gebruiker zullen
                proberen om een minnelijke schikking te vinden. Bij gebrek aan
                minnelijke oplossing, het geschil zal worden uitgevoerd voor de
                rechtbanken van het gerechtelijk arrondissement van RESTOLASTMINUTE
                hoofdkantoor of op die van het gerechtelijk arrondissement waar
                de gebruiker is gevestigd of woont. De relaties tussen
                RESTOLASTMINUTE en de gebruiker zijn uitsluitend beheerst door het
                Belgisch recht.
              </p>
              <p>&nbsp;</p>
            </Typography>
          </div>
        )}

        <Footer locale={locale} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locale } = state;

  const { lang } = locale;

  return {
    lang,
    locale: locale.locale
  };
};

export default withRouter(
  connect(mapStateToProps)(withRoot(withStyles(styles)(UsagePage)))
);
