import { geocodeAddress } from "./geocode";
import { ENDPOINT } from "../config";

export const DEALS_REQUEST = "DEALS_REQUEST";
export const DEALS_SUCCESS = "DEALS_SUCCESS";
export const DEALS_FAILURE = "DEALS_FAILURE";

export const dealsRequest = location => ({
  type: DEALS_REQUEST,
  location
});

export const dealsSuccess = (location, deals) => ({
  type: DEALS_SUCCESS,
  deals: deals
});

export const dealsFailure = (location, error) => ({
  type: DEALS_FAILURE,
  error: error
});

const fetchDeals = (location, lang = "fr") => dispatch => {
  dispatch(dealsRequest(location));

  return fetch(
    `${ENDPOINT}/b2c/dayDeals2?lat=${location.lat}&lon=${location.lng}&locale=${lang}}`
  )
    .then(response => response.json())
    .then(json => {
      dispatch(
        dealsSuccess(
          location,
          json.deals.sort((a, b) => a.distance - b.distance) 
        )
      );
    });
};

export const fetchDealsIfNeeded = (locationQuery, lang) => (
  dispatch,
  getState
) => {
  const { deals, locale } = getState();

  if (deals.isFetching) {
    return;
  }

  geocodeAddress(locationQuery).then(location => {
    dispatch(fetchDeals(location, lang));
  });
};
