import React, { Component } from "react";
import { render } from "react-dom";

import * as serviceWorker from "./serviceWorker";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

import "./bootstrap";
import ConnectedIntlProvider from "./app/ConnectedIntlProvider";
import Routes from "./app/routes";

const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(...middleware)
    // other store enhancers if any
  )
);

render(
  <Provider store={store}>
    <ConnectedIntlProvider>
      <Routes />
    </ConnectedIntlProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
