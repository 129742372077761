import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/styles/withStyles";
import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flag";
import { Fade, Link, ListItemIcon, withWidth } from "@material-ui/core";
import { LOCALE, LOCALES } from "../config";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from "react-intl";

const styles = theme => ({});

class LocaleMenu extends React.Component {


  static propTypes = {
    value: PropTypes.string.isRequired,
    onLocaleClick: PropTypes.func.isRequired
  };

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (event, lang) => {
    this.props.onLocaleClick(lang);
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <IconButton
          aria-owns={anchorEl ? "locale-menu" : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={this.handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="locale-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          TransitionComponent={Fade}
        >
          {Object.values(LOCALES).map(locale => (
            <MenuItem
              key={locale.locale}
              selected={locale.locale === this.props.value}
              onClick={event => this.handleMenuItemClick(event, locale.locale)}
            >
              {locale.language}
            </MenuItem>
          ))}
            <MenuItem
              key="pro"
              component={Link}
              target="_blank"
              href={LOCALES[this.props.value].b2b}

            >
                PRO
            </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(LocaleMenu);
