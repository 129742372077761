import React from "react";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { withStyles, Grid, withWidth, GridList } from "@material-ui/core";
import Deal from "./Deal";
import chunk from "lodash.chunk";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  element: {
    display: "inline-block",
  },
  stepper: {
    background: "#00000000",
  },
  feedImage: {
    marginRight: theme.spacing.unit * 1,
  },
});

function DealsFeed({ width, name, image, deals, classes }) {
  const [activeStep, setActiveStep] = React.useState(0);

  console.log(width);

  let chunkSize;

  switch (width) {
    case "xs":
      chunkSize = 1;
      break;
    case "sm":
      chunkSize = 1;
      break;

    case "md":
      chunkSize = 2;
      break;

    case "lg":
      chunkSize = 4;
      break;

    case "xl":
      chunkSize = 6;
      break;
    default:
      chunkSize = 1;
  }

  const dealsChunk = chunk(deals, chunkSize);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item>
          <img
            className={classes.feedImage}
            height="35px"
            width="35px"
            src={image}
          />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography variant="h5">{name}</Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="small"
            component={RouterLink}
            variant="text"
            to="/restaurants"
          >
            <FormattedMessage id="allDeals" />
          </Button>
        </Grid>
       
      </Grid>
      <SwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents

      >
        {dealsChunk &&
          dealsChunk.map((deals, index) => (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ overflowX: "hidden", overflowY: "hidden", display:"flex", alignItems: "center" }}
            >
              {dealsChunk[activeStep].map((deal) => (
                <Grid item key={deal.id} xs={12 / chunkSize}>
                  <Deal
                    className={classes.element}
                    deal={deal}
                    key={deal.id}
                    webview={false}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
      </SwipeableViews>
      <MobileStepper
        steps={dealsChunk.length}
        position="static"
        variant="text"
        className={classes.stepper}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === dealsChunk.length - 1}
          >
            <FormattedMessage id="next" />
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            <FormattedMessage id="back" />
          </Button>
        }
      />
    </div>
  );
}

export default withWidth()(withStyles(styles)(DealsFeed));
