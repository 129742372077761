import { geocodeAddress } from "./geocode";
import { ENDPOINT } from "../config";

export const RESTAURANTS_REQUEST = "RESTAURANTS_REQUEST";
export const RESTAURANTS_SUCCESS = "RESTAURANTS_SUCCESS";
export const RESTAURANTS_FAILURE = "RESTAURANTS_FAILURE";

export const restaurantsRequest = (location, offset, limit) => ({
  type: RESTAURANTS_REQUEST,
  location,
  offset,
  limit
});

export const restaurantsSuccess = (
  location,
  offset,
  limit,
  count,
  restaurants
) => ({
  type: RESTAURANTS_SUCCESS,
  offset,
  limit,
  count,
  restaurants
});

export const restaurantsFailure = (location, error) => ({
  type: RESTAURANTS_FAILURE,
  error: error
});

const replaceDefaultRestaurantImage = restaurant => {
  return restaurant.map(r => {
    if (r.pictureUrl.includes("default-resto-mobile")) {
      const id = Math.floor(Math.random() * 4) + 1;
      r.pictureUrl = `/default_${id}.jpg`;
    }
    return r;
  });
};

const fetchRestaurants = (
  position,
  lang = "fr",
  offset = 0,
  limit = 50,
  search = "",
  distance
) => dispatch => {
  dispatch(restaurantsRequest(position, offset, limit));

  return fetch(
    `${ENDPOINT}/b2c/restaurants?locale=${lang}&offset=${offset}&limit=${limit}&lat=${position.lat}&lon=${position.lng}&search=${search}&distance=${distance}`
  )
    .then(response => response.json())
    .then(json => {
      dispatch(
        restaurantsSuccess(
          position,
          offset,
          limit,
          json.count,
          replaceDefaultRestaurantImage(json.restaurants)
        )
      );
    })
    .catch(error => {
      dispatch(restaurantsFailure(error));
    });
};

export const fetchRestaurantsIfNeeded = (
  locationQuery,
  offset,
  query = "",
  distance
) => (dispatch, getState) => {
  const { restaurants, locale } = getState();

  geocodeAddress(locationQuery).then(location => {
    dispatch(
      fetchRestaurants(
        location,
        locale.lang,
        offset,
        restaurants.limit,
        query,
        distance
      )
    );
  });
};
