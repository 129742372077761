import { all } from "../config/cities";

export const geocodeAddress = address => {
  const position = all[address];
  if (!position) {
    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&language=fr&key=AIzaSyCvqLwEejgH-Ywoa5QT5GCafVtqmX83r9w`
    )
      .then(response => response.json())
      .then(data => ({
        query: data.address,
        formattedAddress: data.results[0].formatted_address,
        lat: data.results[0].geometry.location.lat,
        lng: data.results[0].geometry.location.lng
      }));
  } else {
    return Promise.resolve(position);
  }
};
