import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withRoot from "../app/withRoot";
import withStyles from "@material-ui/styles/withStyles";
import {
  Grid,
  Typography,
  Button,
  Toolbar,
  AppBar,
  Link,
  Paper,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import LocaleMenu from "../components/LocaleMenu";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import { Link as RouterLink } from "react-router-dom";
import { updateLocaleIfneeded } from "../actions/locale";
import { LOCALES } from "../config";
import ToolBarMain from "../components/ToolBarMain";
import TopBar from "../components/TopBar";

const styles = (theme) => ({
  root: {
    overflowX: "hidden",
  },
  topSection: {
    backgroundImage: `url("top.jpg")`,
    backgroundSize: "cover",

    color: theme.palette.common.white,
  },
  contentSection: {
    paddingLeft: "20%",
    paddingRight: "20%",
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 5,
  },
});

class DisclaimerPage extends Component {
  handleLocaleClick = (lang) => {
    this.props.dispatch(updateLocaleIfneeded(lang));
  };

  render() {
    const { classes, lang, locale } = this.props;
    return (
      <div className={classes.root}>
        <ScrollToTop />
        <TopBar onLocaleClick={this.handleLocaleClick} locale={locale} />
        {lang === "fr" ? (
          <div className={classes.contentSection}>
            <Typography>
              <h1>Politique de Confidentialité</h1>

              <p>Date de prise d'effet: April 08, 2019</p>

              <p>
                SP Management SRL ("nous", "notre", "nos") exploite le site web
                http://restolastminute.com (ci-après désigné par le terme
                "Service").
              </p>

              <p>
                Cette page vous explique nos politiques en matière de collecte,
                d'utilisation et de communication des données à caractère
                personnel lorsque vous utilisez notre Service ainsi que les
                choix qui s'offrent à vous en ce qui concerne ces données.
              </p>

              <p>
                Nous utilisons vos données pour fournir et améliorer le Service.
                En utilisant le Service, vous consentez à la collecte et à
                l'utilisation d'informations conformément à la présente
                politique. Sauf définition contraire dans la présente Politique
                de Confidentialité, les termes utilisés dans la présente
                Politique de Confidentialité ont la même signification que dans
                nos Conditions Générales qui sont disponibles sur
                http://restolastminute.com
              </p>

              <h2>Définitions</h2>
              <ul>
                <li>
                  <p>
                    <strong>Service</strong>
                  </p>
                  <p>
                    Par Service on entend le site web http://restolastminute.com
                    exploité par SP Management SRL
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Données à caractère personnel</strong>
                  </p>
                  <p>
                    Données à Caractère Personnel désigne des données concernant
                    un individu vivant qui peut être identifié à partir de ces
                    données (ou à partir de ces données et d'autres informations
                    en notre possession ou susceptibles d'entrer en notre
                    possession).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Données d'Utilisation</strong>
                  </p>
                  <p>
                    Les Données d'Utilisation sont recueillies automatiquement
                    et sont générées soit par l'utilisation du Service, soit par
                    l'infrastructure du Service proprement dite (par exemple,
                    durée de consultation d'une page).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies</strong>
                  </p>
                  <p>
                    Les cookies sont de petits fichiers enregistrés sur votre
                    dispositif (ordinateur ou dispositif mobile).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Responsable du Traitement</strong>
                  </p>
                  <p>
                    Responsable du Traitement désigne la personne physique ou
                    morale (seule, ou conjointement ou en commun avec d'autres
                    personnes) qui détermine les finalités et les modalités
                    selon lesquelles toutes les données personnelles sont
                    traitées ou doivent l'être.
                  </p>
                  <p>
                    Aux fins de la présente Politique de Confidentialité, nous
                    sommes un Responsable du Traitement de vos données à
                    caractère personnel.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Sous-Traitants (ou Prestataires de Services)
                    </strong>
                  </p>
                  <p>
                    Le terme Sous-Traitant (ou Prestataire de Service) désigne
                    toute personne physique ou morale qui traite les données au
                    nom du Responsable du Traitement.
                  </p>
                  <p>
                    Il se peut que nous fassions appel aux services de plusieurs
                    Prestataires de Services afin de traiter vos données plus
                    efficacement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Personne Concernée (ou Utilisateur)</strong>
                  </p>
                  <p>
                    Par Personne Concernée, on entend toute personne vivante qui
                    utilise notre Service et est le sujet de Données à Caractère
                    Personnel.{" "}
                  </p>
                </li>
              </ul>

              <h2>Collecte et utilisation des données</h2>
              <p>
                Nous recueillons plusieurs types de données à différentes fins
                en vue de vous fournir notre Service et de l'améliorer.
              </p>

              <h3>Types de données recueillies</h3>

              <h4>Données à Caractère Personnel</h4>
              <p>
                Lorsque vous utilisez notre Service, il est possible que nous
                vous demandions de nous fournir certaines données personnelles
                nominatives qui peuvent servir à vous contacter ou à vous
                identifier ("Données à Caractère Personnel"). Les données
                personnelles nominatives peuvent comprendre, mais de manière non
                limitative:
              </p>

              <ul>
                <li>Adresse e-mail</li> <li>Prénom et nom de famille</li>{" "}
                <li>Numéro de téléphone</li>{" "}
                <li>Cookies et Données d'Utilisation</li>
              </ul>

              <p>
                Nous pouvons utiliser vos Données à Caractère Personnel pour
                vous envoyer des bulletins d'information, des communications
                commerciales ou des promotions et d'autres informations
                susceptibles de vous intéresser. Vous pouvez choisir de ne
                recevoir aucune communication de notre part ou de ne recevoir
                que certaines communications en cliquant sur le lien de
                désabonnement ou en suivant les instructions qui figurent dans
                chacun des e-mails que nous envoyons.
              </p>

              <h4>Données d'Utilisation</h4>

              <p>
                Nous pouvons également recueillir des informations relatives au
                mode d'accès et d'utilisation du Service ("Données
                d'Utilisation"). Ces Données d'Utilisation peuvent comprendre
                des informations telles que l'adresse de protocole Internet
                (c.-à-d. l'adresse IP) de votre ordinateur, le type de
                navigateur, la version du navigateur, les pages de notre Service
                que vous consultez, la date et l'heure de votre visite, le temps
                passé sur ces pages, les identifiants uniques de dispositifs
                ainsi que d'autres données de diagnostic.
              </p>

              <h4>Suivi et données de cookies</h4>
              <p>
                Nous avons recours à des cookies et à d'autres technologies de
                suivi similaires pour effectuer un suivi des activités
                effectuées dans notre Service, et nous conservons certaines
                informations.
              </p>
              <p>
                Les cookies sont des fichiers à faible volume de données pouvant
                comporter un identifiant unique anonyme. Les cookies sont
                envoyés à votre navigateur depuis un site web et sont stockés
                sur votre dispositif. D'autres technologies de suivi telles que
                les pixels, les balises et les scripts sont également utilisées
                pour recueillir et suivre des informations et afin d'améliorer
                et d'analyser notre Service.
              </p>
              <p>
                Vous pouvez demander à votre navigateur de refuser tous les
                cookies ou de vous avertir lorsqu'un cookie est envoyé.
                Toutefois, si vous n'acceptez pas les cookies, il se peut que
                vous ne puissiez pas utiliser certains éléments de notre
                Service.
              </p>
              <p>Exemples de cookies que nous utilisons :</p>
              <ul>
                <li>
                  <strong>Cookies de Session.</strong> Nous utilisons des
                  Cookies de Session pour faire fonctionner notre Service.
                </li>
                <li>
                  <strong>Cookies de Préférences.</strong> Nous utilisons des
                  Cookies de Préférences pour mémoriser vos préférences et vos
                  différents paramètres.
                </li>
                <li>
                  <strong>Cookies de Sécurité.</strong> Nous utilisons des
                  Cookies de Sécurité pour des raisons de sécurité.
                </li>
              </ul>

              <h2>Utilisation des données</h2>
              <p>
                SP Management SRL utilise les données recueillies à des fins
                diverses:
              </p>
              <ul>
                <li>Pour fournir et assurer notre Service</li>
                <li>
                  Pour vous faire part des changements apportés à notre Service
                </li>
                <li>
                  Pour vous permettre d'utiliser les fonctions interactives de
                  notre Service quand vous le souhaitez
                </li>
                <li>Pour assurer l'assistance client</li>
                <li>
                  Pour recueillir des données précieuses ou d'analyses qui nous
                  permettront d'améliorer notre Service
                </li>
                <li>Pour contrôler l'utilisation de notre Service</li>
                <li>
                  Pour détecter, prévenir et régler les problèmes techniques
                </li>
                <li>
                  Pour vous faire part de l'actualité, d'offres spéciales et
                  d'informations d'ordre général concernant d'autres
                  marchandises, services et événements que nous proposons et qui
                  sont similaires à ceux que vous avez déjà achetés ou au sujet
                  desquels vous vous êtes déjà renseigné, sauf si vous avez déjà
                  indiqué que vous ne voulez pas recevoir d'informations de
                  cette nature.
                </li>
              </ul>

              <h2>
                Fondement juridique du traitement des données à caractère
                personnel en vertu du Règlement Général relatif à la Protection
                des Données (RGPD)
              </h2>
              <p>
                Si vous résidez dans l'Espace économique européen (EEE), le
                fondement juridique de SP Management SRL en ce qui concerne la
                collecte et l'utilisation des données personnelles décrites dans
                la présente Politique de Confidentialité dépend des Données à
                Caractère Personnel que nous recueillons et du contexte précis
                dans lequel nous les recueillons.
              </p>
              <p>
                SP Management SRL peut traiter vos Données à Caractère
                Personnel:
              </p>
              <ul>
                <li>
                  Parce que nous avons besoin d'exécuter un contrat passé avec
                  vous
                </li>
                <li>Parce que vous nous avez autorisés à le faire</li>
                <li>
                  Parce que nous avons un intérêt légitime à effectuer ce
                  traitement et que vos droits ne priment pas sur cet intérêt
                  légitime
                </li>
                <li>Pour respecter la loi</li>
              </ul>

              <h2>Conservation des données</h2>
              <p>
                SP Management SRL ne conservera vos Données à Caractère
                Personnel que tant que cela sera nécessaire aux fins stipulées
                dans la présente Politique de Confidentialité. Nous conserverons
                et utiliserons vos Données à Caractère Personnel dans la mesure
                où cela sera nécessaire pour que nous puissions nous acquitter
                de nos obligations légales (par exemple, pour respecter la
                législation en vigueur), résoudre des différends et appliquer
                nos accords et nos politiques.
              </p>
              <p>
                SP Management SRL conservera également les Données d'Utilisation
                à des fins d'analyses internes. Les Données d'Utilisation sont
                généralement conservées pour une période plus courte, sauf
                lorsque ces données sont utilisées pour renforcer la sécurité ou
                pour améliorer les fonctionnalités de notre Service, ou si nous
                sommes légalement tenus de conserver ces données plus longtemps.
              </p>

              <h2>Transfert des données</h2>
              <p>
                Les informations vous concernant, notamment vos Données à
                Caractère Personnel, peuvent être transférées de votre région,
                province, pays, ou autre division territoriale vers des
                ordinateurs – et stockées sur ces derniers – situés à un endroit
                où la législation relative à la protection des données diffère
                de celle du territoire où vous résidez.
              </p>
              <p>
                Si vous résidez hors de/du Belgium et que vous choisissez de
                nous communiquer des informations, sachez que nous transférons
                les données, y compris les Données à Caractère Personnel, vers
                le/la Belgium et que nous les y traitons.
              </p>
              <p>
                En acceptant la présente Politique de Confidentialité puis en
                soumettant ces informations, vous consentez à ce transfert.
              </p>
              <p>
                SP Management SRL prendra toutes les mesures raisonnablement
                nécessaires pour faire en sorte que vos données soient traitées
                de manière sécurisée et conformément à la présente Politique de
                Confidentialité et vos Données à Caractère Personnel ne seront
                transférées vers aucune organisation ni aucun pays à moins que
                des contrôles adéquats ne soient en place, notamment en ce qui
                concerne la sécurité de vos données et d'autres données
                personnelles.
              </p>

              <h2>Communication de données</h2>

              <h3>Exigences légales</h3>
              <p>
                SP Management SRL peut communiquer vos Données à Caractère
                Personnel si elle estime de bonne foi que cela est nécessaire
                pour:
              </p>
              <ul>
                <li>S'acquitter d'une obligation légale</li>
                <li>
                  Protéger et défendre les droits ou les biens de SP Management
                  SRL
                </li>
                <li>
                  Prévenir d'éventuels actes répréhensibles ou enquêter sur de
                  tels actes dans le cadre du Service{" "}
                </li>
                <li>
                  Assurer la sécurité personnelle des utilisateurs du Service ou
                  du public
                </li>
                <li>Se protéger contre la responsabilité civile</li>
              </ul>

              <h2>Sécurité des données</h2>
              <p>
                La sécurité de vos données nous tient à cœur. Toutefois,
                n'oubliez pas qu'aucune méthode de transmission de données par
                Internet ou méthode de stockage électronique n'est sûre à 100 %.
                Bien que nous nous efforcions d'utiliser des méthodes
                commercialement acceptables pour protéger vos Données à
                Caractère Personnel, nous ne pouvons pas leur garantir une
                sécurité absolue.
              </p>

              <h2>
                Droits à la protection des données qui vous sont accordés par le
                Règlement Général relatif à la Protection des Données (RGPD)
              </h2>
              <p>
                Si vous résidez dans l'Espace économique européen (EEE), vous
                avez certains droits en matière de protection des données. SP
                Management SRL entend prendre des mesures raisonnables pour vous
                permettre de corriger, de modifier, ou de supprimer vos Données
                à Caractère Personnel ou d'en limiter l'utilisation.
              </p>
              <p>
                Si vous souhaitez savoir quelles Données à Caractère Personnel
                nous détenons à votre sujet et voulez qu'elles soient supprimées
                de nos systèmes, veuillez nous contacter.
              </p>
              <p>
                Dans certaines circonstances, vous avez les droits suivants en
                matière de protection des données:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>
                      Le droit d'accéder aux informations que nous détenons à
                      votre sujet, de les actualiser ou de les supprimer.
                    </strong>{" "}
                    Lorsque cette option est proposée, vous pouvez consulter ou
                    actualiser vos Données à Caractère Personnel ou en demander
                    la suppression dans la section paramètres de votre compte.
                    Si vous ne pouvez pas effectuer ces actions vous-même, merci
                    de nous contacter pour obtenir de l'aide.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Droit de rectification.</strong> Vous avez le droit
                    de faire rectifier vos données si elles sont inexactes ou
                    incomplètes.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Droit d'opposition.</strong> Vous avez le droit de
                    vous opposer à ce que nous traitions vos Données à Caractère
                    Personnel.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Droit de limitation</strong> Vous avez le droit de
                    nous demander de limiter le traitement de vos données
                    personnelles.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Le droit à la portabilité des données.</strong> Vous
                    avez le droit de recevoir une copie des informations que
                    nous détenons à votre sujet dans un format couramment
                    utilisé, structuré et lisible par une machine.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Droit au retrait du consentement.</strong> Vous avez
                    également le droit de retirer votre consentement à tout
                    moment si SP Management SRL s'est appuyé sur votre
                    consentement pour traiter vos données personnelles.
                  </p>
                </li>
              </ul>
              <p>
                Veuillez noter que nous pouvons vous demander de prouver votre
                identité avant de répondre à des demandes de cette nature.
              </p>

              <p>
                Vous avez le droit de déposer plainte auprès d'une autorité de
                protection des données au sujet de notre collecte et de notre
                utilisation de vos Données à Caractère Personnel. Pour plus de
                précisions, veuillez contacter l'autorité de protection des
                données la plus proche de vous dans l'Espace économique européen
                (EEE).
              </p>

              <h2>Prestataires de services</h2>
              <p>
                Nous pouvons faire appel à des sociétés tierces et à des tierces
                personnes pour faciliter la prestation de notre Service
                ("Prestataires de Services"), assurer le Service en notre nom,
                assurer des services liés au Service ou nous aider à analyser la
                façon dont notre Service est utilisé.
              </p>
              <p>
                Ces tiers n'ont accès à vos Données à Caractère Personnel que
                pour effectuer ces tâches en notre nom et il leur est interdit
                de les communiquer ou de les utiliser à quelle qu'autre fin.{" "}
              </p>

              <h3>Analyses</h3>
              <p>
                Nous pouvons faire appel à des Prestataires de Services tiers
                pour surveiller et analyser l'utilisation de notre Service.
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Google Analytics</strong>
                  </p>
                  <p>
                    Google Analytics est un service d'analyse web proposé par
                    Google qui assure le suivi du trafic d'un site web et en
                    rend compte. Google utilise les données recueillies pour
                    suivre et surveiller l'utilisation de notre Service. Ces
                    données sont partagées avec d'autres services Google. Google
                    peut utiliser les données recueillies pour contextualiser et
                    personnaliser les annonces de son propre réseau
                    publicitaire.
                  </p>
                  <p>
                    Vous pouvez empêcher que vos activités dans le cadre du
                    Service ne soient mises à la disposition de Google Analytics
                    en installant le plug-in pour navigateur Analytics Opt out
                    browser add-on de Google Analytics. Ce plug-in empêche le
                    code JavaScript de Google Analytics JavaScript (ga.js,
                    analytics.js et dc.js) de partager les informations
                    concernant les activités liées aux visites avec Google
                    Analytics.{" "}
                  </p>{" "}
                  <p>
                    Pour plus de précisions sur les pratiques de confidentialité
                    de Google, merci de consulter la page web Protection de la
                    vie privée et conditions de Google:{" "}
                    <a href="https://policies.google.com/privacy?hl=en">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </p>
                </li>
              </ul>

              <h2>Liens pointant vers d'autres sites</h2>
              <p>
                Il se peut que notre Service contienne des liens pointant vers
                d'autres sites que nous n'exploitons pas. Si vous cliquez sur un
                lien de tiers, vous serez redirigé vers le site de ce tiers.
                Nous vous recommandons vivement d'examiner la politique de
                confidentialité de chacun des sites que vous consultez.
              </p>
              <p>
                Nous n'avons aucun contrôle sur le contenu, les politiques ou
                pratiques de confidentialité des sites ou services de tiers et
                déclinons toute responsabilité en ce qui les concerne.
              </p>

              <h2>Vie privée des enfants</h2>
              <p>
                Notre Service ne s'adresse pas aux personnes de moins de 18 ans
                ("Enfants").
              </p>
              <p>
                Nous ne recueillons pas sciemment de données personnelles
                nominatives auprès de personnes de moins de 18 ans. Si vous êtes
                un parent ou un tuteur et que vous savez que votre Enfant nous a
                communiqué des Données à Caractère Personnel, veuillez nous
                contacter. Si nous apprenons que nous avons recueilli des
                Données à Caractère Personnel auprès d'enfants sans vérifier
                s'il y a consentement parental, nous faisons le nécessaire pour
                supprimer ces informations de nos serveurs.
              </p>

              <h2>Modifications de la présente Politique de Confidentialité</h2>
              <p>
                Nous nous réservons le droit d'actualiser notre Politique de
                Confidentialité de temps à autre. Nous vous informerons de toute
                modification en publiant la nouvelle Politique de
                Confidentialité sur cette page.
              </p>
              <p>
                Avant que la modification ne prenne effet, nous vous en
                informerons par e-mail et/ ou en plaçant un avis bien en vue
                dans notre Service et nous actualiserons la "date de prise
                d'effet" qui figure en haut de la présente Politique de
                Confidentialité.
              </p>
              <p>
                Nous vous conseillons de consulter la présente Politique de
                Confidentialité périodiquement pour prendre connaissance de
                toute modification. Les modifications apportées à la présente
                Politique de Confidentialité prennent effet lorsqu'elles sont
                publiées sur cette page.
              </p>

              <h2>Nous contacter</h2>
              <p>
                Pour toute question relative à la présente Politique de
                Confidentialité, veuillez nous contacter:
              </p>
              <ul>
                <li>Par courrier électronique: hello@restolastminute.com</li>
              </ul>
            </Typography>
          </div>
        ) : (
          <div className={classes.contentSection}>
            <Typography>
              <h1>Privacybeleid</h1>

              <p>Ingangsdatum: April 08, 2019</p>

              <p>
                SP Management SRL ("ons", "wij" of "onze") beheert de
                http://restolastminute.com website ("hierna genoemd Dienst").
              </p>

              <p>
                Deze pagina bevat informatie over ons beleid met betrekking tot
                de verzameling, het gebruik en de openbaarmaking van uw
                persoonsgegevens wanneer u onze Dienst gebruikt en de keuzes die
                u hebt met betrekking tot die gegevens.
              </p>

              <p>
                Wij gebruiken uw gegevens om de Dienst te leveren en te
                verbeteren. Wanneer u de Dienst gebruikt, gaat u akkoord met de
                verzameling en het gebruik van informatie in overeenstemming met
                dit beleid. Tenzij anders bepaald in dit Privacybeleid heeft de
                terminologie die wordt gebruikt in dit Privacybeleid dezelfde
                betekenis als in onze Algemene voorwaarden, beschikbaar op
                http://restolastminute.com
              </p>

              <h2>Definities</h2>
              <ul>
                <li>
                  <p>
                    <strong>Dienst</strong>
                  </p>
                  <p>
                    Onder dienst verstaan wij de http://restolastminute.com
                    website beheerd door SP Management SRL
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Gebruiksgegevens</strong>
                  </p>
                  <p>
                    Onder gebruiksgegevens verstaan wij automatisch verzamelde
                    gegevens die worden gegenereerd door het gebruik van de
                    Dienst of van de infrastructuur van de Dienst zelf
                    (bijvoorbeeld, de duur van het bezoek aan een pagina).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Gebruiksgegevens</strong>
                  </p>
                  <p>
                    Onder gebruiksgegevens verstaan wij automatisch verzamelde
                    gegevens die worden gegenereerd door het gebruik van de
                    Dienst of van de infrastructuur van de Dienst zelf
                    (bijvoorbeeld, de duur van het bezoek aan een pagina).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies</strong>
                  </p>
                  <p>
                    Cookies zijn informatiebestandjes die worden opgeslagen op
                    uw apparaat (computer of mobiele apparaat).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Gegevensbeheerder</strong>
                  </p>
                  <p>
                    Onder gegevensbeheerder verstaan wij de natuurlijke of
                    rechtspersoon die (alleen, gezamenlijk of gemeenschappelijk
                    met andere personen) de doeleinden bepaalt waarvoor en de
                    wijze waarop persoonsgegevens worden of zullen worden
                    verwerkt.
                  </p>
                  <p>
                    In het kader van dit Privacybeleid zijn wij een
                    Gegevensbeheerder van uw Persoonsgegevens.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Gegevensverwerkers (of Dienstverleners)</strong>
                  </p>
                  <p>
                    Onder Gegevensverwerkers (of Dienstverleners) verstaan wij
                    elke natuurlijke of rechtspersoon die de gegevens verwerkt
                    in naam van de Gegevensverwerker.
                  </p>
                  <p>
                    Wij kunnen de diensten gebruiken van verschillende
                    Dienstverleners om uw gegevens efficiënter te kunnen
                    verwerken.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Betrokkene (of Gebruiker)</strong>
                  </p>
                  <p>
                    De Betrokkene is elke levende persoon die gebruik maakt van
                    onze Dienst en die het onderwerp is van Persoonsgegevens.
                  </p>
                </li>
              </ul>

              <h2>Gegevensverzameling en gebruik</h2>
              <p>
                Wij verzamelen verschillende soorten gegevens voor uiteenlopende
                doeleinden om onze Dienst aan u te kunnen leveren en om hem te
                verbeteren.
              </p>

              <h3>Soorten gegevens die worden verzameld</h3>

              <h4>Persoonsgegevens</h4>
              <p>
                Wanneer u onze Dienst gebruikt, kunnen wij u vragen ons bepaalde
                persoonlijk identificeerbare informatie te verstrekken die kan
                worden gebruikt om contact op te nemen met u of om u te
                identificeren ("Persoonsgegevens"). Deze persoonlijk
                identificeerbare informatie kan omvatten maar is niet beperkt
                tot:
              </p>

              <ul>
                <li>E-mailadres</li> <li>Voor- en achternaam</li>{" "}
                <li>Telefoonnummer</li> <li>Cookies en Gebruiksgegevens</li>
              </ul>

              <p>
                Wij kunnen uw Persoonsgegevens gebruiken om contact op te nemen
                met u via nieuwsbrieven, marketing of promotiemateriaal en
                andere informatie die mogelijk interessant is voor u. U kunt
                aangeven dat u slechts bepaalde, of helemaal geen communicatie
                wenst te ontvangen door te klikken op de afmeldlink of door de
                instructies te volgen die wij u in een e-mailbericht verzenden.
              </p>

              <h4>Gebruiksgegevens</h4>

              <p>
                Wij kunnen ook gegevens verzamelen over de wijze waarop de
                gebruiker toegang krijgt tot de Dienst en hoe deze wordt
                gebruikt ("Gebruiksgegevens") Deze Gebruiksgegevens kunnen
                informatie bevatten zoals het Internet Protocol adres (IP-adres)
                van uw computer, het type browser, de versie van de browser, de
                pagina's die u hebt bezocht op onze Dienst, het tijdstip en de
                datum van uw bezoek, de tijd die u hebt doorgebracht op die
                pagina's, unieke apparaat-ID's en andere diagnostische gegevens.
              </p>

              <h4>Tracking &amp; cookiegegevens</h4>
              <p>
                Wij gebruiken cookies en soortgelijke volgtechnologieën om de
                activiteit op onze Dienst te volgen en we bewaren bepaalde
                informatie.
              </p>
              <p>
                Cookies zijn bestanden met een kleine hoeveelheid gegevens die
                een anonieme unieke ID kunnen bevatten. Cookies worden van een
                website verzonden naar uw browser en opgeslagen op uw apparaat.
                Er worden ook andere volgtechnologieën gebruikt zoals beacons,
                tags en scripts om gegevens te verzamelen en te volgen en om
                onze Dienst te verbeteren en te analyseren.
              </p>
              <p>
                U kunt uw browser instellen om alle cookies te weigeren of om
                aan te geven wanneer een cookie wordt verzonden. Maar als u geen
                cookies aanvaardt, kunt u mogelijk niet alle functies van onze
                Dienst gebruiken.
              </p>
              <p>Voorbeelden van cookies die wij gebruiken:</p>
              <ul>
                <li>
                  <strong>Sessiecookies.</strong> Wij gebruiken Sessiecookies om
                  onze Dienst te beheren.
                </li>
                <li>
                  <strong>Voorkeurcookies.</strong> Wij gebruiken
                  Voorkeurcookies om uw voorkeuren en uiteenlopende instellingen
                  bij te houden.
                </li>
                <li>
                  <strong>Veiligheidscookies.</strong> Wij gebruiken
                  Veiligheidscookies voor veiligheidsdoeleinden.
                </li>
              </ul>

              <h2>Gebruik van gegevens</h2>
              <p>
                SP Management SRL gebruikt de verzamelde gegevens voor
                uiteenlopende doeleinden:
              </p>
              <ul>
                <li>Om onze Dienst te leveren en te onderhouden</li>
                <li>Om u wijzigingen in onze Dienst te melden</li>
                <li>
                  Om u de mogelijkheid te bieden om, indien gewenst, deel te
                  nemen aan de interactieve functies van onze Dienst
                </li>
                <li>Om onze klanten steun te verlenen</li>
                <li>
                  Om analyse- of waardevolle gegevens te verzamelen die we
                  kunnen toepassen om onze Dienst te verbeteren
                </li>
                <li>Om toezicht te houden op het gebruik van onze Dienst</li>
                <li>
                  Om technische problemen te detecteren, te voorkomen en te
                  behandelen
                </li>
                <li>
                  Om u nieuws, speciale aanbiedingen en algemene informatie te
                  bieden over onze goederen, diensten en evenementen die
                  gelijkaardig zijn aan wat u in het verleden al gekocht hebt of
                  waar u informatie over hebt gevraagd, tenzij u hebt aangegeven
                  dat u dergelijke informatie niet wenst te ontvangen.
                </li>
              </ul>

              <h2>
                Juridische basis voor de verwerking van Persoonsgegevens onder
                de Algemene verordening persoonsgegevens (AVG)
              </h2>
              <p>
                Als u inwoner bent van de Europese Economische Ruimte (EER)
                hangt de juridische basis van, SP Management SRL voor de
                verzameling en het gebruik van de persoonsgegevens zoals
                beschreven in dit Privacybeleid af van de Persoonsgegevens die
                wij verzamelen en de specifieke context waarin wij ze
                verzamelen.
              </p>
              <p>SP Management SRL kan uw Persoonsgegevens gebruiken omdat:</p>
              <ul>
                <li>Wij een contract moeten afsluiten met u</li>
                <li>U ons de toestemming hebt gegeven</li>
                <li>
                  De verwerking in ons legitiem belang is en niet wordt
                  opgeheven door uw rechten
                </li>
                <li>In naleving van de wet</li>
              </ul>

              <h2>Bewaren van gegevens</h2>
              <p>
                SP Management SRL bewaart uw Persoonsgegevens slechts zolang dit
                nodig is voor de doeleinden vermeld in dit Privacybeleid. Wij
                bewaren en gebruiken uw Persoonsgegevens in de mate dit
                noodzakelijk is om te voldoen aan onze wettelijke verplichtingen
                (bijv. als we uw gegevens moeten bewaren in naleving van de
                geldende wetgeving), om geschillen op te lossen en om onze
                wettelijke overeenkomsten en beleid toe te passen.
              </p>
              <p>
                SP Management SRL bewaart uw Gebruiksgegevens ook voor interne
                analytische doeleinden. Gebruiksgegevens worden gewoonlijk voor
                een kortere periode bewaard, tenzij deze gegevens worden
                gebruikt om de veiligheid te versterken of de werking van onze
                Dienst te verbeteren, of indien wij wettelijk verplicht zijn
                deze gegevens gedurende een langere periode te bewaren.
              </p>

              <h2>Overdracht van gegevens</h2>
              <p>
                Uw gegevens, inclusief Persoonsgegevens, kunnen worden
                overgedragen naar - en bewaard op - computers die zich buiten
                het rechtsgebied van uw provincie, land of een andere
                overheidsinstantie bevinden waar de wetgeving inzake
                gegevensbescherming kan verschillen van de wetgeving in uw
                rechtsgebied.
              </p>
              <p>
                Let erop dat, als u zich buiten Belgium bevindt en u ons
                gegevens verstrekt, wij deze gegevens, inclusief
                Persoonsgegevens, overdragen naar Belgium en ze daar verwerken.
              </p>
              <p>
                Uw instemming met dit Privacybeleid gevolgd door uw indiening
                van dergelijke gegevens geven aan dat u akkoord gaat met die
                overdracht.
              </p>
              <p>
                SP Management SRL zal alle redelijkerwijs noodzakelijke stappen
                ondernemen om ervoor te zorgen dat uw gegevens veilig en in
                overeenstemming met dit Privacybeleid worden behandeld en dat uw
                Persoonsgegevens nooit worden overgedragen aan een organisatie
                of een land als er geen gepaste controles zijn ingesteld,
                inclusief de veiligheid van uw gegevens en andere
                persoonsgegevens.
              </p>

              <h2>Openbaarmaking van gegevens</h2>

              <h3>Wettelijke vereisten</h3>
              <p>
                SP Management SRL kan uw Persoonsgegevens openbaar maken als het
                te goeder trouw de mening is toegedaan dat een dergelijke
                handeling noodzakelijk is:
              </p>
              <ul>
                <li>Om te voldoen aan een wettelijke verplichting</li>
                <li>
                  Om de rechten en eigendom van SP Management SRL te beschermen
                  en te verdedigen
                </li>
                <li>
                  Om mogelijke misstanden te voorkomen of te onderzoeken in
                  verband met de Dienst
                </li>
                <li>
                  Om de persoonlijke veiligheid van gebruikers van de Dienst of
                  het publiek te beschermen
                </li>
                <li>Als bescherming tegen juridische aansprakelijkheid</li>
              </ul>

              <h2>Veiligheid van gegevens</h2>
              <p>
                De veiligheid van uw gegevens is belangrijk voor ons, maar
                vergeet niet dat geen enkele methode van verzending via het
                internet of elektronische methode van opslag 100% veilig is.
                Hoewel wij ernaar streven commercieel aanvaardbare middelen toe
                te passen om uw Persoonsgegevens te beschermen, kunnen wij de
                absolute veiligheid niet waarborgen.
              </p>

              <h2>
                Uw rechten inzake gegevensbescherming onder de Algemene
                verordening persoonsgegevens (AVG)
              </h2>
              <p>
                Als u inwoner bent van de Europese Economische Ruimte (EER) hebt
                u bepaalde rechten op het gebied van gegevensbescherming. SP
                Management SRL streeft er naar redelijke maatregelen te nemen om
                u de mogelijkheid te bieden het gebruik van uw Persoonsgegevens
                te corrigeren, te wijzigen, te verwijderen of te beperken.
              </p>
              <p>
                Als u wilt weten welke Persoonsgegevens wij van u bezitten en
                als u wenst te worden verwijderd uit onze systemen kunt u
                contact opnemen met ons.
              </p>
              <p>
                In bepaalde omstandigheden hebt u de volgende rechten op het
                gebied van gegevensbescherming:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>
                      Het recht van toegang, bijwerking of verwijdering van de
                      informatie die wij van u bezitten.
                    </strong>{" "}
                    Waar mogelijk hebt u toegang tot uw Persoonsgegevens en kunt
                    u ze bijwerken of de verwijdering rechtstreeks in uw
                    accountinstellingen aanvragen. Als u deze acties niet zelf
                    kunt uitvoeren, kunt u contact opnemen met ons om u te
                    helpen.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Het recht op correctie.</strong> U hebt het recht uw
                    informatie te laten corrigeren als die informatie
                    onnauwkeurig of onvolledig is.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Het recht om bezwaar te maken.</strong> U hebt het
                    recht om bezwaar te maken tegen de verwerking van uw
                    Persoonsgegevens.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Het recht op beperking.</strong> U hebt het recht te
                    vragen dat wij de verwerking van uw Persoonsgegevens
                    beperken.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Het recht op gegevensportabiliteit.</strong> U hebt
                    het recht een kopie te ontvangen van de informatie die wij
                    van u bezitten in een gestructureerd, machinaal leesbaar en
                    algemeen gebruikt formaat.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Het recht uw toestemming in te trekken.</strong> U
                    hebt ook het recht uw toestemming op elk moment in te
                    trekken waar SP Management SRL uw persoonsgegevens verwerkt
                    op basis van uw toestemming.
                  </p>
                </li>
              </ul>
              <p>
                Denk eraan dat wij u kunnen vragen uw identiteit te verifiëren
                voor wij ingaan op dergelijke aanvragen.
              </p>

              <p>
                U hebt het recht een klacht in te dienen bij de Autoriteit
                Persoonsgegevens met betrekking tot onze verzameling en gebruik
                van uw Persoonsgegevens. Voor meer informatie kunt u contact
                opnemen met uw lokale Autoriteit Persoonsgegevens in de Europese
                Economische Ruimte (EER).
              </p>

              <h2>Dienstverleners</h2>
              <p>
                Wij kunnen externe bedrijven en personen aanstellen om onze
                Dienst ("Dienstverleners") te vereenvoudigen, om de Dienst te
                leveren in onze naam, om diensten uit te voeren in het kader van
                onze Dienst of om ons te helpen bij de analyse van hoe onze
                Dienst wordt gebruikt.
              </p>
              <p>
                Deze externe partijen hebben enkel toegang tot uw
                Persoonsgegevens om deze taken uit te voeren in onze naam en zij
                mogen deze niet openbaar maken aan anderen of ze gebruiken voor
                andere doeleinden.
              </p>

              <h3>Analytics</h3>
              <p>
                Wij kunnen beroep doen op externe Dienstverleners om het gebruik
                van onze Dienst te volgen en te analyseren.
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Google Analytics</strong>
                  </p>
                  <p>
                    Google Analytics is een webanalyse-service van Google die
                    het websiteverkeer volgt en rapporteert. Google gebruikt de
                    verzamelde gegevens om het gebruik van onze Dienst te volgen
                    en bij te houden. Deze gegevens worden gedeeld met andere
                    Google diensten. Google kan de verzamelde gegevens gebruiken
                    om de advertenties van zijn eigen advertentienetwerk te
                    contextualiseren en te personaliseren.
                  </p>
                  <p>
                    U kunt aangeven dat u uw activiteit op de Dienst niet
                    beschikbaar wenst te maken voor Google Analytics door de
                    Google Analytics opt-out browser add-on te installeren. Deze
                    add-on zorgt ervoor dat het Google Analytics JavaScript
                    (ga.js, analytics.js en dc.js) geen informatie kan delen met
                    Google Analytics over uw activiteiten op het internet.
                  </p>{" "}
                  <p>
                    Voor meer informatie over de privacypraktijken van Google
                    verwijzen wij u naar de internetpagina van Google Privacy en
                    voorwaarden:{" "}
                    <a href="https://policies.google.com/privacy?hl=en">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </p>
                </li>
              </ul>

              <h2>Links naar andere sites</h2>
              <p>
                Onze Dienst kan links bevatten naar andere sites die niet door
                ons worden beheerd. Als u klikt op een link van een externe
                partij wordt u naar de site van die externe partij gebracht. Wij
                raden u sterk aan het Privacybeleid te verifiëren van elke site
                die u bezoekt.
              </p>
              <p>
                Wij hebben geen controle over en aanvaarden geen
                aansprakelijkheid met betrekking tot de inhoud, het
                privacybeleid of de privacypraktijken van de sites of diensten
                van een externe partij.
              </p>

              <h2>Privacy van kinderen</h2>
              <p>
                Onze dienst richt zich niet op personen die jonger zijn dan 18
                ("Kinderen").
              </p>
              <p>
                Wij verzamelen nooit bewust persoonlijk identificeerbare
                informatie van iemand die jonger is dan 18 jaar oud. Als u een
                ouder of voogd bent en u stelt vast dat uw kind ons
                persoonsgegevens heeft geleverd, vragen wij u contact op te
                nemen met ons. Als u vaststelt dat wij persoonsgegevens hebben
                verzameld van kinderen zonder de verificatie van ouderlijk
                toezicht zullen wij de nodige stappen ondernemen om die
                informatie te verwijderen van onze servers.
              </p>

              <h2>Wijzigingen aan dit Privacybeleid</h2>
              <p>
                Wij kunnen ons Privacybeleid op gezette tijden bijwerken. Wij
                zullen u op de hoogte brengen van eventuele wijzigingen door het
                nieuwe Privacybeleid te publiceren op deze pagina.
              </p>
              <p>
                Wij zullen u op de hoogte brengen via e-mail en/of een
                duidelijke melding op onze Dienst voor de wijzigingen van kracht
                gaan en wij zullen de "aanvangsdatum" bijwerken die vermeld
                staat bovenaan in dit Privacybeleid.
              </p>
              <p>
                Wij raden u aan dit Privacybeleid regelmatig te controleren op
                eventuele wijzigingen. Wijzigingen aan dit Privacybeleid gaan
                van kracht op het moment dat ze worden gepubliceerd op deze
                pagina.
              </p>

              <h2>Contact opnemen</h2>
              <p>
                Als u vragen hebt over dit Privacybeleid kunt u contact opnemen
                met ons:
              </p>
              <ul>
                <li>Via email: hello@restolastminute.com</li>
              </ul>
            </Typography>
          </div>
        )}
        <Footer locale={locale} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locale } = state;

  const { lang } = locale;

  return {
    lang,
    locale: locale.locale,
  };
};

export default withRouter(
  connect(mapStateToProps)(withRoot(withStyles(styles)(DisclaimerPage)))
);
