import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Link, AppBar, Toolbar } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import SearchBar from "./SearchBar";
import LocaleMenu from "./LocaleMenu";
import { Link as RouterLink } from "react-router-dom";
import SearchBarAlt from "./SearchBarAlt";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 5
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  langButton: {
    marginLeft: 20,
    marginRight: -12
  }
});

const TopBar = ({
  classes,
  onSearchClick,
  location,
  onLocaleClick,
  locale,
}) => {
  return (
    <header>
      <AppBar position="static" color="white">
        <Toolbar>
          <Link className={classes.menuButton} component={RouterLink} to={`/`}>
            <img src="/logo.png" alt="RestoLastMinute" width="40" height="40" />
          </Link>
          <div className={classes.grow} />
          {(onSearchClick && location) && <SearchBarAlt onSearchClick={onSearchClick} value={location} /> }
          <div className={classes.langButton}>
            <LocaleMenu onLocaleClick={onLocaleClick} value={locale} />
          </div>
        </Toolbar>
      </AppBar>
    </header>
  );
};
TopBar.propTypes = {
  location: PropTypes.string.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onLocaleClick: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TopBar);
