import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  CardActionArea,
  CardMedia,
  CardContent,
  Card,
  withStyles,
  Grid,
  Divider,
  Link,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Map, Call, OpenInBrowser, Restaurant, Info } from "@material-ui/icons";
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  isBefore,
} from "date-fns";
import fr from "date-fns/locale/fr";
import nl from "date-fns/locale/nl";

import { FormattedMessage } from "react-intl";
import ReservationModal from "../containers/ReservationModal";
import ReservationRequestModal from "../containers/ReservationRequestModal";

const styles = (theme) => ({
  about: {
    maxWidth: 360,
  },
  card: {},
  percentage: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  mapImg: {
    width: 360,
  },
});

const RestaurantDetails = ({
  restaurant,
  classes,
  onReservationClick,
  reservation,
  lang,
}) => {
  const {
    id,
    name,
    city,
    pictureUrl,
    street,
    zipcode,
    phone,
    website,
    cuisines = [],
    menuUrl,
    deal,
  } = restaurant;

  if (restaurant.deal) {
    var now = new Date();
    var endBookingDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      restaurant.deal && restaurant.deal.dinner
        ? restaurant.deal.endBookingHour
        : 13,
      restaurant.deal && restaurant.deal.dinner
        ? restaurant.deal.endBookingMinute
        : 1
    );
  }

  const date = format(new Date(), "dddd D MMMM", {
    locale: lang === "nl" ? nl : fr,
  });

  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={40}
    >
      <Grid item>
        <Grid container direction="column" spacing={32}>
          <Grid item>
            <Typography variant="h3">{name}</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={8}>
              <Grid item>
                <Map />
              </Grid>
              <Grid item>
                <Link
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                    `${street}+${city}`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="body1">
                    {street} - {zipcode} {city}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" spacing={8}>
              <Grid item>
                <Call />
              </Grid>
              <Grid item>
                <Link
                  href={`tel:${phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="body1" align="center">
                    {phone}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" spacing={8}>
              <Grid item>
                <OpenInBrowser />
              </Grid>
              <Grid item>
                <Link href={website} target="_blank" rel="noopener noreferrer">
                  <Typography variant="body1" align="center">
                    <FormattedMessage id="restaurant.website" />
                  </Typography>
                </Link>
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center" spacing={8}>
              <Grid item>
                <Restaurant />
              </Grid>
              <Grid item>
                <Typography variant="body1" align="center">
                  {cuisines.join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          {restaurant.deal ? (
            <Grid item>
              <div className={classes.about}>
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id="dealDetails.title" />
                </Typography>
                <Typography variant="body1" paragraph>
                  <FormattedMessage
                    id="dealDetails.description"
                    values={{
                      date: date,
                      name: name,
                      availablePlaces: restaurant.deal.availablePlaces,
                      percentage: restaurant.deal.percentage,
                    }}
                  />
                </Typography>
                <Typography variant="body2" paragraph>
                  <FormattedMessage id="dealDetails.description.footer" />
                </Typography>
                {menuUrl ? (
                  <Button
                    href={menuUrl}
                    variant="outlined"
                    color="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="dealDetails.menu" />
                  </Button>
                ) : null}
              </div>
            </Grid>
          ) : (
            <Grid item>
              <div className={classes.about}>
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id="dealDetails.title" />
                </Typography>
                <Typography variant="body1" paragraph>
                  <FormattedMessage
                    id="restaurant.description"
                    values={{
                      name,
                      city,
                    }}
                  />
                </Typography>
                <Typography variant="body2" paragraph>
                  <FormattedMessage id="restaurant.description.footer" />
                </Typography>
                {menuUrl ? (
                  <Button
                    href={menuUrl}
                    variant="outlined"
                    color="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="dealDetails.menu" />
                  </Button>
                ) : null}
              </div>
            </Grid>
          )}

          <Grid item>
            <img
              src={pictureUrl}
              alt="{name}"
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={40}>
          {restaurant.deal ? (
            <Grid item>
              <Card className={classes.card}>
                <CardContent>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={32}
                  >
                    {restaurant.deal.percentage ? (
                      <Grid item className={classes.percentage} color="inherit">
                        <Typography variant="h3" color="inherit" align="center">
                          <FormattedMessage
                            id="dealDetails.reduction"
                            values={{ percentage: restaurant.deal.percentage }}
                          />
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Typography variant="h6" justify="center" align="center">
                        {restaurant.deal.pause ? (
                          <Typography
                            color="primary"
                            variant="subtitle1"
                            inline
                          >
                            <FormattedMessage id="deal.pause" />
                          </Typography>
                        ) : restaurant.deal.availablePlaces > 0 &&
                          isBefore(now, endBookingDate) ? (
                          <FormattedMessage
                            id="deal.availablePlaces"
                            values={{
                              availablePlaces: restaurant.deal.availablePlaces,
                            }}
                          />
                        ) : (
                          <FormattedMessage id="deal.noMorePlaces" />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ReservationModal
                        deal={restaurant.deal}
                        onReservationClick={onReservationClick}
                        reservation={reservation}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ) : restaurant.email ? (
            <Grid item>
              <Card className={classes.card}>
                <CardContent>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={32}
                  >
                    <Grid item>
                      <ReservationRequestModal
                        restaurantId={restaurant.id}
                        onReservationClick={onReservationClick}
                        reservation={reservation}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ) : null}

          <Grid item>
            <Link
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                `${street}+${city}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classes.mapImg}
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${restaurant.lat},${restaurant.lon}&zoom=14&scale=1&size=360x360&markers=${restaurant.lat},${restaurant.lon}&key=AIzaSyCvqLwEejgH-Ywoa5QT5GCafVtqmX83r9w`}
                alt="map"
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RestaurantDetails.propTypes = {
  restaurant: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onReservationClick: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default withStyles(styles)(RestaurantDetails);
