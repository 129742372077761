import { ENDPOINT } from "../config";

export const RESTAURANT_SELECT = "RESTAURANT_SELECT";
export const RESTAURANT_REQUEST = "RESTAURANT_REQUEST";
export const RESTAURANT_SUCCESS = "RESTAURANT_SUCCESS";
export const RESTAURANT_FAILURE = "RESTAURANT_FAILURE";

export const restaurantSelect = restaurant => ({
  type: RESTAURANT_SELECT,
  restaurant
});

export const restaurantRequest = id => ({
  type: RESTAURANT_REQUEST,
  id
});

export const restaurantSuccess = (id, json) => ({
  type: RESTAURANT_SUCCESS,
  restaurant: json.restaurant
});

export const restaurantFailure = (id, error) => ({
  type: RESTAURANT_FAILURE,
  error: error
});

export const fetchRestaurantIfNeeded = id => (dispatch, getState) => {
  const { restaurants, locale } = getState();
  const restaurant = restaurants.items.find(restaurant => restaurant.id === id);
  if (restaurant) {
    return dispatch(restaurantSelect(restaurant));
  }
  return dispatch(fetchRestaurant(id, locale.lang));
};

const fetchRestaurant = (id, lang = "fr") => dispatch => {
  dispatch(restaurantRequest(id));
  //location is a dummy query here
  return fetch(`${ENDPOINT}/b2c/restaurants/${id}?locale=${lang}`)
    .then(response => response.json())
    .then(json => dispatch(restaurantSuccess(id, json)));
};
