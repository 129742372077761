import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { InputBase, Paper, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FormattedMessage } from "react-intl";
import { fade, makeStyles } from "@material-ui/core/styles";

const styles = theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,

    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 4,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "primary"
  },
  inputInput: {
    paddingLeft: theme.spacing.unit * 8,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 220,
      "&:focus": {
        width: 300
      }
    }
  }
});

class SearchBarAlt extends Component {
  static propTypes = {
    value: PropTypes.string,
    onSearchClick: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { searchInput: props.value };
  }

  handleInputChange = event => {
    this.setState({
      searchInput: event.target.value
    });
  };

  handleSearchClick = () => {
    this.props.onSearchClick(this.state.searchInput);
  };

  handleKeyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.handleSearchClick();
    }
  };

  render() {
    const { value, classes } = this.props;
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon color="textSecondary" />
        </div>
        <FormattedMessage
          id="search.placeholder"
          defaultMessage="Où voulez-vous manger?"
        >
          {placeholder => (
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              value={this.state.searchInput}
              onChange={this.handleInputChange}
              placeholder={placeholder}
              onKeyDown={this.handleKeyPress}
            />
          )}
        </FormattedMessage>
      </div>
    );
  }
}

export default withStyles(styles)(SearchBarAlt);
