import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { InputBase, Paper, IconButton, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FormattedMessage } from "react-intl";

const styles = theme => ({
  paper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    maxWidth: 360,
    minWidth: 360
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  searchButton: {
    padding: 10
  }
});

class SearchBar extends Component {
  static propTypes = {
    value: PropTypes.string,
    onSearchClick: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { searchInput: props.value };
  }

  handleInputChange = event => {
    this.setState({
      searchInput: event.target.value
    });
  };

  handleSearchClick = () => {
    this.props.onSearchClick(this.state.searchInput);
  };

  handleKeyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.handleSearchClick();
    }
  };

  render() {
    const { value, classes } = this.props;
    return (
      <Paper className={classes.paper} elevation={1}>
        <FormattedMessage
          id="search.placeholder"
          defaultMessage="Où voulez-vous manger?"
        >
          {placeholder => (
            <InputBase
              className={classes.input}
              value={this.state.searchInput}
              onChange={this.handleInputChange}
              placeholder={placeholder}
              onKeyDown={this.handleKeyPress}
            />
          )}
        </FormattedMessage>
        <IconButton
          className={classes.searchButton}
          color="primary"
          aria-label="Search"
          onClick={this.handleSearchClick}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }
}

export default withStyles(styles)(SearchBar);
